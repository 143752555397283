import { Box, Button, Link, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import PdfModal from "service/forms/common/PdfModal";
import { formatDate } from "sharedUtils";
import { useTicket } from "ticket/selectors";
import {
  useFormServiceActionMutation,
  useGetLocationServiceVendorQuery,
} from "api/services";

import ReportRequestCardLayout from "./ReportRequestCardLayout"

const statusMessages = {
  awaiting_response: { text: "Awaiting Formal Response", color: "#FFA600" },
  cancelled: { text: "Cancelled", color: "red" },
  vendor_replied: { text: "Pending Review", color: "#FFA600" },
  accepted: { text: "response accepted", color: "#19E53B" },
  rejected: {  text: "Response Rejected", color: "red" },
  open: { text: 'Open is a status on this remediation action?', color: 'red' }
};

const RemediationCard = ({ remediation, isLog = false }) => {
  const ticket = useTicket();
  const [openModal, setOpenModal] = useState(false);
  const [pdfUrl, setPdfUrl] = useState('')

  const [submitForm] = useFormServiceActionMutation();
  const [serviceUuid, setServiceUuid] = useState("");
  const { data: locationServiceVendor } = useGetLocationServiceVendorQuery({
    locationId: `${ticket?.location?.id}`,
    vendorId: ticket?.vendor?.id,
  }, { skip: !ticket?.location?.id || !ticket?.vendor?.id });

  useEffect(() => {
    if (!locationServiceVendor) return;
    setServiceUuid(locationServiceVendor[ticket?.vendor?.id].services[0].uuid);
  }, [locationServiceVendor]);

  const statusMessage = statusMessages[remediation?.status];
  const acceptRemediation = () => {
    const acceptUrl = `/admin/service_actions/${serviceUuid}/accept_remediation_response`;
    submitResponse(acceptUrl);
  };

  const rejectRemediation = () => {
    const rejectUrl = `/admin/service_actions/${serviceUuid}/reject_remediation_response`;
    submitResponse(rejectUrl);
  };

  const submitResponse = (url) => {
    submitForm({
      url: url,
      body: {
        tagged_reply_id: remediation.lastTaggedReplyId,
        form: {
          vendor_id: ticket?.vendor?.id,
          ticket_id: ticket?.id,
          location_uuid: ticket?.location?.uuid,
          service_uuid: serviceUuid,
          authority_id: ticket?.location?.authority_id,
        },
      },
    });
  };

  const pdfUrls = {
    notice: remediation?.noticePdfUrl,
    response: remediation?.replyPdfUrl
  };

  const handleOpenPdf = (pdfType) => () => {
    setOpenModal(true);
    setPdfUrl(pdfUrls[pdfType]);
  };

  const title = remediation ? (
    <>
      {remediation.status === "rejected" ? "Latest Remediation " : "Remediation "}
      <Typography
        component="span"
        sx={{
          color: statusMessage.color,
        }}
      >
        {` ${statusMessage.text}`}
      </Typography>
      {
        remediation.status === 'rejected' ?
            <>
              {" "} by {remediation.contractManagerFullName},{" "}
            <Typography
                component='span'
                sx={{
                  color: '#FFA600',
                }}>
                Awaiting Formal Response
              </Typography>
            </>
            : ""}
    </>
  ) : (<></>)

  const label = remediation ? (
    <>
      for <strong>{remediation.serviceActionReason}</strong> 
      {remediation.status !== 'awaiting_response' && remediation.status !== 'cancelled' && remediation.status !== 'rejected' && (
        <> by <strong>{remediation.contractManagerFullName}</strong></>
      )}
    </>
  ) : (<></>)

  const links = remediation ? (
    <>
      {remediation?.replyPdfUrl && (
        <Link
          onClick={handleOpenPdf('response')}
          underline="none"
          sx={{ cursor: "pointer", mr: 1 }}
        >
          Open Response
        </Link>
      )}
      <Link
        onClick={handleOpenPdf('notice')}
        underline="none"
        sx={{
          cursor: "pointer",
        }}
      >
        Open Notice
      </Link></>
  ) : (<></>)


  const body = remediation ? (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography sx={{ fontSize: "11px", fontWeight: "400", color: "#828B98" }}>
          Date of contract failure{" "}
          <span style={{ color: "#BFBFBF" }}>{formatDate(remediation.dateOfContractFailure)}</span>
        </Typography>
        <Typography color="grey" sx={{ fontSize: "11px", color: "#5F6369" }}>
          Requested on <span style={{ color: "#828B98" }}>{formatDate(remediation?.requestedOn)}</span> by{" "}
          <span style={{ color: "#828B98" }}>{remediation.inputedByFullName || "No Agent"}</span>
        </Typography>

      </Box>
      <Typography sx={{ fontSize: "11px", color: "#828B98", mb: isLog ? 1 : 0 }}>
        {remediation.message}
      </Typography>

      {remediation?.status == "vendor_replied" && (
        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2, gap: 1 }}>
         <Button
           variant="contained"
           color="success"
           sx={{ textTransform: "none" }}
           onClick={() => acceptRemediation()}
         >
           Accept
         </Button>
         <Button
           variant="contained"
           color="error"
           sx={{ textTransform: "none" }}
           onClick={() => rejectRemediation()}
         >
           Reject
         </Button>
        </Box>
      )}
      <PdfModal open={openModal} onClose={() => setOpenModal(false)} pdfUrl={pdfUrl} />
    </>

  ) : (<></>)

  return remediation ? (
    <ReportRequestCardLayout title={title} links={links} label={label} body={body} request={remediation} />
  ) : (<></>);
};

export default RemediationCard;
