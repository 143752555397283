import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Alert, Box, Collapse, ListItemButton } from "@mui/material";
import { groupBy, map } from "lodash";
import { useState } from "react";
import { StockDeliveryRequests } from "service/ticket/types";
import StockDeliveryRequestCard from "./StockDeliveryCard";

interface StockDeliveryRequestTabProps {
  stockDeliveryRequests: StockDeliveryRequests;
}

const StockDeliveryRequestTab: React.FC<StockDeliveryRequestTabProps> = ({
  stockDeliveryRequests,
}) => {
  const [openStates, setOpenStates] = useState(() => {
    let initialStates = {};
    if (stockDeliveryRequests) {
      stockDeliveryRequests.forEach((request) => {
        initialStates[request.id] = false;
        initialStates[`group-${request.linkedTicketIds[0]}`] = false;
      });
    }
    return initialStates;
  });

  const handleClick = (id: string) => {
    setOpenStates((prevStates) => ({
      ...prevStates,
      [id]: !prevStates[id],
    }));
  };

  const groupedRequests = groupBy(stockDeliveryRequests, (request) => {
    return request?.linkedTicketIds[0];
  });

  return (
    <>
      {Object.values(stockDeliveryRequests).length === 0 && (
        <Alert severity="warning" sx={{ p: 2, fontSize: "14px" }}>
          No requests to show
        </Alert>
      )}
      {map(groupedRequests, (requests, ticketId) => (
        <Box key={ticketId}>
          <ListItemButton
            onClick={() => handleClick(`group-${ticketId}`)}
            sx={{ fontSize: "12px", fontWeight: "bold", pb: 1, color: "#fff" }}
          >
            Ticket ID: {ticketId}{" "}
            {openStates[`group-${ticketId}`] ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>

          <Collapse
            in={openStates[`group-${ticketId}`]}
            timeout="auto"
            unmountOnExit
          >
            {requests.map((request) => (
              <StockDeliveryRequestCard
                key={request.id}
                request={request}
                isOpen={openStates[request.id]}
                toggleOpen={() => handleClick(request.id)}
              />
            ))}
          </Collapse>
        </Box>
      ))}
    </>
  );
};

export default StockDeliveryRequestTab;
