import _ from 'lodashExtended'

const getGeneralIssues = (state) => {
  return _.reject(state.issues.generalIssues, { _destroy: '1'} )
}

const getGeneralIssue = (id, state) => {
  return state.issues.generalIssues[id]
}

export {
  getGeneralIssues,
  getGeneralIssue,
}
