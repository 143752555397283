import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LinkIcon from "@mui/icons-material/Link";
import { RemediationIcon } from "service/utils/Icons";
import { useGetLocationRemediationQuery } from "api/services";

import { flatMap, map, size, startCase, toLower, mapKeys, camelCase } from "lodash";

import { DebugButton } from "service/GlobalDebugger";
import { Link, Card, CardContent, Stack, Box, Collapse, Divider, IconButton, Typography } from "@mui/material";
import { TextSmallMuted, TextSmall, TextMuted } from 'sharedComponents';
import { useContext, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { formatDate } from "sharedUtils";
import { getLocationId, getTicketId, useTicket } from "ticket/selectors";
import AppContext from "ticket/AppContext";


const ReportRequestCardLayout = ({ request, title, label, links, body, startOpen = true }) => {

  const [open, setOpen] = useState(startOpen);

  const { stakeholderContacts } = useTicket() || {};
  const history = useHistory();

  const location = useLocation();
  const locationId = getLocationId();

  const {
    currentTicketId,
    highlightedIds,
    setHighlightedIds,
    onOpenRemediation,
    setRequestedAtDate,
    setEventId,
    setRequestType,
    setServiceUuid,
    setShouldScroll,
  } = useContext(AppContext);


  const remediationData = useGetLocationRemediationQuery(locationId, {skip: !locationId}).data?.remediation;
  const remediation = remediationData ? mapKeys(remediationData, (_, k) => camelCase(k)) : null;

  // Get all stakeholders
  const allStakeholders: Contact[] = flatMap(
    stakeholderContacts || [],
    (x) => x,
  );

  // Find who inputted the request
  // TODO: Get inputtedById on the request ?
  const inputtedBy = allStakeholders?.find(
    (x) => x.id === request?.inputtedById,
  );

  const isInCurrentPath = (request?.linkedTicketIds && request.linkedTicketIds?.length > 0)
    ? currentTicketId === String(request?.linkedTicketIds[0])
    : false;
  const isInCurrentPathRemediation = currentTicketId === String(request?.remediationTicketId)

  const isSelected = useMemo(() => {
    return (
      request?.replyIds &&
      request?.replyIds.length > 0 &&
      request?.replyIds?.every((id) => highlightedIds?.includes(String(id)))
    );
  }, [highlightedIds]);


//   if(label === 'bob') {
//   debugger
// }

  const toggleHighlightedReplies = () => {

    const searchParams = new URLSearchParams(location.search);

    if (String(request.linkedTicketIds[0]) === currentTicketId) {
      // We're on the same ticket
      let newHighlights = [];

      if (isSelected) {
        // If this request is already selected, deselect it
        newHighlights = [];
      } else {
        // If this request is not selected, select it and deselect others
        newHighlights = [...request.replyIds];
      }

      // Update search params
      searchParams.delete("highlight");
      if (newHighlights.length > 0) {
        newHighlights.forEach((id) => searchParams.append("highlight", id));
      }

      setHighlightedIds(newHighlights);
      setShouldScroll(true);

      // Update the URL and push to history
      history.replace({
        pathname: location.pathname,
        search: searchParams.toString(),
      });
    } else {
      // We are not on the same ticket
      const ticketId = request?.linkedTicketIds[0];
      const queryParam = `highlight=${request?.replyIds.join("&highlight=")}`;
      const newTabUrl = `/admin/locations/${locationId}/app#/tickets/${ticketId}?${queryParam}`;
      window.open(newTabUrl, "_blank");
    }
  };
  const toggleHighlightedRepliesRemediation = () => {
    const searchParams = new URLSearchParams(location.search);

    if (String(remediation.remediationTicketId) === currentTicketId) {
      // We're on the same ticket
      let newHighlights = [];

      if (isSelected) {
        // If this request is already selected, deselect it
        newHighlights = [];
      } else {
        // If this request is not selected, select it and deselect others
        newHighlights = [...remediation.replyIds];
      }

      // Update search params
      searchParams.delete("highlight");
      if (newHighlights.length > 0) {
        newHighlights.forEach((id) => searchParams.append("highlight", id));
      }

      setHighlightedIds(newHighlights);

      // Update the URL and push to history
      history.replace({
        pathname: location.pathname,
        search: searchParams.toString(),
      });
    } else {
      // We are not on the same ticket
      const ticketId = remediation?.remediationTicketId;
      const queryParam = `highlight=${remediation?.replyIds.join("&highlight=")}`;
      const newTabUrl = `/admin/locations/${locationId}/app#/tickets/${ticketId}?${queryParam}`;
      window.open(newTabUrl, "_blank");
    }
  };

  return (

      <Box
        key={request.id}
        data-link-card={request.id}
        sx={{
          background: "#1E1E1E",
          color: "#BFBFBF",
          borderTop: '1px solid #656D78',
        }}
      >


        <Stack direction="row"
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
            cursor: 'pointer',
            pl: '10px',
            minHeight: '40px',
            backgroundColor:  open ? "#121212" : "inherit",
            borderBottom: '0px dashed #4B4B4B',
            borderWidth:  open ? "1px" : "0",
          }}
        >

          <Box>
            <strong>{title}</strong>
          </Box>

          <Box>
            { request?.linkedTicketIds && (
              <>
                {isInCurrentPath ? (
                  <IconButton size="small"
                    onClick={() => toggleHighlightedReplies()} sx={{
                    color: isSelected ? "#4FC1E9 !important" : "#5F6369",
                  }}>
                    <LinkIcon/>
                  </IconButton>
                ) : (
                  <Typography variant="xsmall">
                    <Link onClick={() => toggleHighlightedReplies()}

                      sx={{
                        color: "#5F6369",
                        "&:hover": {
                          ...(isInCurrentPath ? { color: "#5F6369" } : {}),
                        },
                      }}
                    >
                      #{request?.linkedTicketIds[0]}
                    </Link>
                  </Typography>
                )}
              </>
            )}
          { request?.remediationTicketId && (
            <>
              {isInCurrentPathRemediation ? (
                <>
                  {links}
                  <IconButton size="small"
                    onClick={() => toggleHighlightedRepliesRemediation()} sx={{
                      color: isSelected ? "#4FC1E9 !important" : "#5F6369",
                    }}>
                    <LinkIcon/>
                  </IconButton>
                </>
              ) : (
                  <Typography variant="xsmall">
                    <Link onClick={() => toggleHighlightedRepliesRemediation()}

                      sx={{
                        color: "#5F6369",
                        "&:hover": {
                          ...(isInCurrentPathRemediation ? { color: "#5F6369" } : {}),
                        },
                      }}
                    >
                      #{request?.remediationTicketId}
                    </Link>
                  </Typography>
                )}
            </>
          )}


            { isInCurrentPath && (!remediation || remediation.status == "accepted" || remediation.status == "cancelled") && (
            <IconButton size="small"
                onClick={() => {
                  setRequestedAtDate(request?.requestedAt || request?.reportedAt);
                  setEventId(request?.id);
                  setRequestType(request?.type);
                  setServiceUuid(request?.serviceUuid);
                  onOpenRemediation();
                }}
            >
              <RemediationIcon />
            </IconButton>
            )}
            <DebugButton buttonSize="small" {...request} />

            <IconButton size="small" onClick={() => setOpen(!open)}>
              {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </Box>

        </Stack>


        <Box
          sx={{
            display: "flex", justifyContent: "space-between", alignItems: "center",
            padding: open ? '5px 10px 5px 10px' : '0px 10px 10px 10px',
          }}
        >
          { label && (
            <Typography variant={open ? 'medium' : 'small'}>
              {label}
            </Typography>
          )}

          { open && (
          <Typography variant="xsmall" sx={{ textAlign: 'right' }}>
            Requested on{" "} <strong> {formatDate(request?.requestedAt)} </strong> by
            <strong> {inputtedBy?.nameWithoutEmail || remediation?.inputedByFullName || "No Agent"} </strong>
          </Typography>
          ) }
        </Box>


        <Collapse in={open} timeout="auto" unmountOnExit>
          <Box sx={{
            m: '6px 0 10px 6px',
            padding: '6px 6px 6px 15px',
            borderLeft: '1px solid #4B4B4B',
            backgroundColor:  open ? "#121212" : "inherit",
            borderTop: '0px dashed #4B4B4B',
            borderBottom: '0px dashed #4B4B4B',
            borderWidth:  open ? "1px" : "0",
          }}>

           {body}
          </Box>
        </Collapse>

      </Box>

  )
}


export default ReportRequestCardLayout;
