import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, IconButton, Stack } from "@mui/material";
import { StakeholderContacts } from "api/tickets";
import {
  prepareCannedResponse,
  useCannedResponses,
} from "hooks/useCannedResponses";
import { useFetch } from "hooks/useFetch";
import { lowerCase, pick } from "lodash";
import React, { createContext, useContext, useEffect, useState } from "react";
import getType from "service/forms";
import { scrollToBottom } from "service/ticket/utils";
import { useFormServiceActionMutation } from "../../api/services";
import { ServiceActionMessageForm } from "./ServiceActionMessageForm";
import FormSkeleton from "./common/FormSkeleton";
import AppContext from "ticket/AppContext";
import { useTicket } from "ticket/selectors";
import AgentSelect from "service/ticket/channel-footer/form/AgentSelect";
import { DebugButton } from "service/GlobalDebugger";

interface NewMessageActionFormProps {
  formUrl: string;
  onClose: () => void;
  stakeholderContacts: {
    organisation: StakeholderContacts;
    agent: StakeholderContacts;
    vendor: StakeholderContacts;
  };
  reply?: any;
}

const formTypeMapping = {
  adhoc: ["adhoc"],
  resumption: ["resumption"],
  stock: ["stock"],
  spec: ["spec"],
  frequency: ["frequency"],
  suspension: ["suspension"],
  termination: ["termination"],
};

export const MessageFormContext = createContext({
  vendorResponse: {
    message: "",
    toContactId: "",
    setToContactId: (_x) => {},
    resetToCannedResponse: () => {},
    setMessage: (_x) => {},
  },
  vendorError: false,
  sendMessageVendor: false,
  setSendMessageVendor: (_x) => {},
  stakeholderContacts: {},
  setVendorError: (_x) => {},
  organisationResponse: {
    message: "",
    toContactId: "",
    setToContactId: (_x) => {},
    resetToCannedResponse: () => {},
    setMessage: (_x) => {},
  },
  organisationError: false,
  sendMessageOrganisation: false,
  setSendMessageOrganisation: (_x) => {},
  setOrganisationError: (_x) => {},
  formData: {},
});


const NewMessageActionForm: React.FC<NewMessageActionFormProps> = ({
  formUrl,
  onClose,
  stakeholderContacts,
  reply,
}) => {
  const ticket = useTicket();
  const { setFormType, setCardTab } = useContext(AppContext);
  const [sendMessageOrganisation, setSendMessageOrganisation] = useState(true);
  const [sendMessageVendor, setSendMessageVendor] = useState(true);

  const [organisationError, setOrganisationError] = useState(false);
  const [vendorError, setVendorError] = useState(false);
  const [assignedAgentId, setAssignedAgentId] = useState(
    ticket?.assignedAgent?.id || ""
  );

  const [formData, setFormData, formLoaded] = useFetch(formUrl, {
    credentials: "same-origin",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  const [submitForm, submitFormResult] = useFormServiceActionMutation();

  const { isError: isSubmitError, isLoading: isSubmittting } = submitFormResult;

  const {
    vendorResponse,
    organisationResponse,
    formDefaults,
    responsesLoaded,
  } = useCannedResponses(formUrl, formData);

  const isOrganisationMessageEmpty =
    sendMessageOrganisation && !organisationResponse?.message;
  const isVendorMessageEmpty = sendMessageVendor && !vendorResponse?.message;

  const FormType = formData ? getType(formData?.type) : null;

  const updateForm = (newFormArgs) => {
    setFormData((prevData) => {
      return {
        ...prevData,
        form: {
          ...prevData.form,
          ...newFormArgs,
        },
      };
    });
  };

  const submitHandler = () => {
    let hasError = false;

    if (isOrganisationMessageEmpty) {
      setOrganisationError(true);
      hasError = true;
    }

    if (isVendorMessageEmpty) {
      setVendorError(true);
      hasError = true;
    }

    if (hasError) return;

    submitForm({
      url: formUrl,
      body: prepareCannedResponse({
        form: {
          ...formData.form,
          assignedAgentId,
          organisationResponse: pick(organisationResponse, [
            "toContactId",
            "responseExpected",
            sendMessageOrganisation && "message",
          ]),
          vendorResponse: pick(vendorResponse, [
            "toContactId",
            "responseExpected",
            sendMessageVendor && "message",
          ]),
        },
      }),
    })
      .unwrap()
      .then(({ data }) => setFormData(data))
      .then(() => {
        const formTypeLowerCase = lowerCase(formData.type);
        // Find the form type based on the formData.type
        const foundFormType = Object.keys(formTypeMapping).find((key) =>
          formTypeMapping[key].some((type) => formTypeLowerCase.includes(type))
        );

        if (formTypeLowerCase.includes("report")) {
          setCardTab("report");
        } else {
          // Set the found form type, default to a fallback if not found
          setFormType(foundFormType || "all");
        }
      })
      .then(() => scrollToBottom())
      .then(() => onClose())
      .catch(({ data }) => setFormData(data));
  };

  const showForm = formLoaded && responsesLoaded && FormType;

  useEffect(() => {
    const errors = formData?.errors;
    if (errors?.vendorMessage) setVendorError(true);
    if (errors?.organisationMessage) setOrganisationError(true);
  }, [formData]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          position: "absolute",
          top: '2px',
          right: '0px',
        }}
      >
        <DebugButton buttonSize="small" {...formData} />
        <IconButton sx={{ color: '#fff', }} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>

      {showForm ? (
        <MessageFormContext.Provider
          value={{
            vendorResponse,
            vendorError,
            sendMessageVendor,
            setSendMessageVendor,
            setVendorError,
            stakeholderContacts,
            organisationResponse,
            organisationError,
            sendMessageOrganisation,
            setSendMessageOrganisation,
            setOrganisationError,
            formData,
          }}
        >

          <Box>

            <FormType
              {...formData}
              replyVisitFailure={reply}
              onSetForm={updateForm}
            />
            <ServiceActionMessageForm formDefaults={formDefaults || formData?.formDefaults} />

          </Box>

          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={1}
            sx={{ width: "100%", backgroundColor: '#637381', padding: '10px', border: '1px solid #646D78', borderTop: 'none', borderBottomRightRadius: '10px', borderBottomLeftRadius: '10px'}}
          >
              <AgentSelect
                assignedAgentId={assignedAgentId}
                setAssignedAgentId={setAssignedAgentId}
              />
            <Box sx={{ flex: 1 }} />
            <Button
              variant="outlined"
              color="info"
              onClick={() => onClose()}
              sx={{ fontSize: "12px", width: "150px", color: '#fff', borderColor: '#868d93',
                    "&:hover": {
                      backgroundColor: "#EEEEEE",
                    },
                  }}
            >
              Cancel
            </Button>
            <Box>
              <Button
                type="submit"
                variant="contained"
                disabled={isSubmittting || organisationError || vendorError}
                onClick={submitHandler}
                color={isSubmitError ? "error" : "success"}
                sx={{ fontSize: "12px", width: "200px" }}
              >
                Submit
              </Button>
            </Box>
          </Stack>
        </MessageFormContext.Provider>
      ) : (
        <FormSkeleton />
      )}
    </>
  );
};

export default NewMessageActionForm;
