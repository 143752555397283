import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "react-infinite-calendar/styles.css";
import "./styles.scss";
import Debugger from "./Debugger";
import { CheckBoxWithLabel, ModelErrors } from "sharedComponents";
import { Box, Stack, Typography } from "@mui/material";
import ServiceCalendarDatePicker from "./common/ServiceCalendarDatePicker";
import dayjs from "dayjs";
import NotesSection from "./common/NotesSection";
import { formatDate } from "sharedUtils";

const ConfirmResumptionForm = ({ form, context, errors, onSetForm }) => {
  const { nextVisits, serviceName, visitsExpectedAfter} = context;

  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(dayjs());

  const visitDays = nextVisits?.map((visit) => {
    return { ...visit, date: dayjs(visit.date) };
  });

  useEffect(() => {
    const formattedDate = dayjs(visitsExpectedAfter).format("DD/MM/YYYY");
    setValue(dayjs(visitsExpectedAfter));
    onSetForm({ confirmedVisitsExpectedAfter: formattedDate });
  }, [visitsExpectedAfter]);

  return (
    <div>
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={3}
      >
        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
          Confirm Resumption
        </Typography>
        <Typography color="grey" variant="h6" sx={{ fontWeight: "bold" }}>
          / {serviceName}
        </Typography>
      </Stack>
      <div
        className="mt-3"
        style={{ display: "flex", flexDirection: "column", gap: "5px" }}
      >
        <div>
          <Box sx={{ marginTop: 1 }}>
            <li>
              <strong>Vendor was requested to resume service from: {formatDate(visitsExpectedAfter)}</strong>
            </li>
          </Box>
          <label>Resumption From</label>
        </div>
        <ServiceCalendarDatePicker
          minDate={dayjs()}
          visitDays={visitDays}
          open={open}
          setOpen={setOpen}
          value={value}
          onChange={(newValue) =>
            onSetForm({ confirmedVisitsExpectedAfter: newValue.format("DD/MM/YYYY") })
          }
        />
        <NotesSection
          form={form}
          onSetForm={onSetForm}
          context={context}
          contactPlaceholder="Who requested the Resumption?"
          contactLabel="Confirmed by"
          dateLabel="Confirmed on"
          dateValue={form?.confirmedAt}
          onChange={(newValue) =>
            onSetForm({
              confirmedAt: newValue.format("DD/MM/YYYY"),
            })
          }
        />
      </div>

      <Debugger {...form} />

      <ModelErrors errors={errors} />
    </div>
  );
};

ConfirmResumptionForm.propTypes = {
  form: PropTypes.shape({
    notes: PropTypes.string,
    confirmedVisitsExpectedAfter: PropTypes.string,
  }),
  errors: PropTypes.object,
  context: PropTypes.shape({
    visitsExpectedAfter: PropTypes.string,
    serviceName: PropTypes.string.isRequired,
    nextVisits: PropTypes.arrayOf(
      PropTypes.shape({
        date: PropTypes.string.isRequired,
        status: PropTypes.string.isRequired,
      })
    ),
  }),
  onSetForm: PropTypes.func.isRequired,
};

export default ConfirmResumptionForm;
