import {
  useGetAdhocVisitRequestsQuery,
  useGetFailedVisitReportsQuery,
  useGetRemediationCasesQuery,
  useGetServiceResumptionRequestsQuery,
  useGetServiceSuspensionRequestsQuery,
  useGetServiceTerminationRequestsQuery,
  useGetSpecChangeRequestsQuery,
  useGetStockDeliveryRequestsQuery,
  useGetTicketQuery,
  useGetVisitFrequencyChangeRequestsQuery,
} from "api/tickets";
import { compact, groupBy, map, orderBy, toArray, union, uniq } from "lodash";
import {
  AdhocVisitRequests,
  FailedVisitReport,
  ServiceResumptionRequests,
  ServiceSuspensionRequests,
  ServiceTerminationRequests,
  SpecChangeRequests,
  StockDeliveryRequests,
  VisitFrequencyChangeRequests,
} from "service/ticket/types";
import { useGetLocationServiceVendorsQuery } from "../../modules/api/services";
import { useTypedSelector } from "./app/store";
import { useContext, useEffect, useRef, useState } from "react";
import AppContext from "./AppContext";
import { useBinFetch } from "sharedUtils";

export const getTicketId = (state) => state?.ticketId;
export const getLocationId = () =>
  useTypedSelector((state) => state?.locationId);

export const getTicketById = (ticketId) => {
  const { data: ticket } = useGetTicketQuery(ticketId);
  return ticket;
};

export const useTicket = () => {
  const { currentTicketId } = useContext(AppContext);
  const ticket = getTicketById(currentTicketId);
  return ticket;
};

export const useVendorFromCurrentTicket = () => {
  const ticket = useTicket();
  return ticket?.vendor?.id;
};

export const useTicketReplies = () => {
  const ticketId = useTypedSelector(getTicketId);
  const { data: ticket } = useGetTicketQuery(ticketId);
  return ticket?.replies;
};

export const getDefaultContactId = (channel) =>
  useTypedSelector(() => {
    const { defaultContactMap } = useTicket();
    return defaultContactMap[channel].id;
  });
export const getDefaultResponseExpected = (channel) => {
  switch (channel) {
    case "organisation":
      return false;
    case "vendor":
      return true;
    default:
      return true;
  }
};

//TODO DONT USE SERVICE SELECTORS in lower components and remove from app props

export const useService = (uuid: string) => {
  const { data: locationServiceVendors } =
    useGetLocationServiceVendorsQuery(getLocationId()) || {};

  if (locationServiceVendors) {
    for (const vendor of toArray(locationServiceVendors)) {
      const service = vendor?.services?.find(
        (service) => service?.uuid === uuid
      );
      if (service) {
        return service;
      }
    }
  }

  return undefined;
};

//TODO END

export const getBinTypeForService = (serviceUuid) => {
  const service = useService(serviceUuid);
  const { specifications } = service || {};
  const { binTypes, binGroups } = useBinFetch();

  return compact(
    map(specifications, (spec) => {
      const binType = binTypes[spec.serviceCode];
      if (binType) {
        const binGroup = binGroups[binType.binGroupId];
        return {
          ...binType,
          quantity: spec.quantity,
          binGroupName: binGroup ? binGroup.name : null,
        };
      }
      return null;
    })
  );
};

const getBinTypesByGroup = (serviceCodes) => {
  const { binTypes, binGroups } = useBinFetch();
  const inUseBinGroups = {};

  map(serviceCodes, (serviceCode) => {
    const binType = binTypes[serviceCode];
    if (binType) {
      const binGroup = binGroups[binType.binGroupId];
      if (!inUseBinGroups[binType.binGroupId]) {
        inUseBinGroups[binType.binGroupId] = {
          id: parseInt(binType.binGroupId),
          name: binGroup.name,
          legendBorderColor: binGroup.legendBorderColor,
          legendFillColor: binGroup.legendFillColor,
          binTypes: [],
        };
      }
      inUseBinGroups[binType.binGroupId].binTypes.push(binType.name);
    }
  });

  return Object.values(inUseBinGroups);
};

export const getInUseBinGroupsForService = (serviceUuid) => {
  const service = useService(serviceUuid);
  const { specifications } = service || {};

  const serviceCodes = map(specifications, (_spec, serviceCode) => serviceCode);

  return getBinTypesByGroup(serviceCodes);
};

export const useFailedVisitReports = (): FailedVisitReport[] | undefined => {
  const { data } = useGetFailedVisitReportsQuery(useTypedSelector(getTicketId));
  return data;
};

// Adhoc and Stock are returned as arrays
export const useAdhocVisitRequests = (): AdhocVisitRequests => {
  const { data } = useGetAdhocVisitRequestsQuery(useTypedSelector(getTicketId));
  const { adhocVisitRequests } = data || {};
  if (adhocVisitRequests) {
    return orderBy(adhocVisitRequests, ["requestedAt"], ["desc"]);
  }
};

export const useStockDeliveryRequests = (): StockDeliveryRequests => {
  const { data } = useGetStockDeliveryRequestsQuery(
    useTypedSelector(getTicketId)
  );
  const stockDeliveryRequests: StockDeliveryRequests =
    data?.stockDeliveryRequests;
  if (stockDeliveryRequests) {
    return orderBy(stockDeliveryRequests, ["requestedAt"], ["desc"]);
  }
};

export const useRemediationCases = () => {
  const { data } = useGetRemediationCasesQuery(useTypedSelector(getTicketId));
  const { remediationCases: remediationCase } = data || {};

  // Return first case, only can exist 1 ?
  const remediationCaseValue =
    remediationCase && Object.keys(remediationCase).length > 0
      ? Object.values(remediationCase)[0]
      : null;

  return remediationCaseValue;
};

export const useServiceSuspensionRequests = (): ServiceSuspensionRequests => {
  const { data } = useGetServiceSuspensionRequestsQuery(
    useTypedSelector(getTicketId)
  );
  const { serviceSuspensionRequests } = data || {};
  if (serviceSuspensionRequests) {
    return orderBy(serviceSuspensionRequests, ["requestedAt"], ["desc"]);
  }
};

export const useServiceTerminationRequests = (): ServiceTerminationRequests => {
  const { data } = useGetServiceTerminationRequestsQuery(
    useTypedSelector(getTicketId)
  );
  const { serviceTerminationRequests } = data || {};
  if (serviceTerminationRequests) {
    return orderBy(serviceTerminationRequests, ["requestedAt"], ["desc"]);
  }
};

export const useServiceResumptionRequests = (): ServiceResumptionRequests => {
  const { data } = useGetServiceResumptionRequestsQuery(
    useTypedSelector(getTicketId)
  );
  const { serviceResumptionRequests } = data || {};
  if (serviceResumptionRequests) {
    return orderBy(serviceResumptionRequests, ["requestedAt"], ["desc"]);
  }
};

export const useSpecChangeRequests = (): SpecChangeRequests => {
  const { data } = useGetSpecChangeRequestsQuery(useTypedSelector(getTicketId));
  const { specChangeRequests } = data || {};
  if (specChangeRequests) {
    return orderBy(specChangeRequests, ["requestedAt"], ["desc"]);
  }
};

export const useVisitFrequencyChangeRequests =
  (): VisitFrequencyChangeRequests => {
    const { data } = useGetVisitFrequencyChangeRequestsQuery(
      useTypedSelector(getTicketId)
    );
    const { visitFrequencyChangeRequests } = data || {};
    if (visitFrequencyChangeRequests) {
      return orderBy(visitFrequencyChangeRequests, ["requestedAt"], ["desc"]);
    }
  };

export const useDeveloper = () => {
  const developer = useTypedSelector((state) => state.developer);
  return developer;
};

export const useGlobalLoadingState = () => {
  const [isLoading, setIsLoading] = useState(false);
  const currentTicketRef = useRef(null);

  const queries = useTypedSelector((state) => state.api.queries);

  useEffect(() => {
    const areRelevantQueriesLoading = Object.keys(queries).some((queryKey) => {
      const query = queries[queryKey];
      if (
        query &&
        (query.status === "pending" || query.status === "uninitialized")
      ) {
        if (queryKey.startsWith("getTicket(")) {
          const match = queryKey.match(/getTicket\("(\d+)"\)/);
          if (match) {
            const newTicketNumber = match[1];
            if (newTicketNumber !== currentTicketRef.current) {
              currentTicketRef.current = newTicketNumber;
              return true;
            }
          }
        }
      }
      return false;
    });

    setIsLoading(areRelevantQueriesLoading);
  }, [queries]);

  return isLoading;
};
