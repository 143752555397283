import {
  Timeline,
  TimelineContent,
  TimelineItem,
  TimelineOppositeContent,
} from "@mui/lab";
import { Typography } from "@mui/material";
import React from "react";
import { formatDate } from "sharedUtils";

interface Note {
  timestamp: string;
  userName: string;
  text: string;
}

interface CustomTimelineProps {
  notes: Note[];
}

const CustomTimelineNotes: React.FC<CustomTimelineProps> = ({ notes }) => {
  if (!notes.length) return null;
  return (
    <Timeline
      sx={{
        pb: 0,
        "& .MuiTimelineOppositeContent-root": {
          flex: 0.2,
        },
        "& .MuiTimelineItem-root": {
          minHeight: 0,
        },
      }}
    >
      {notes.map((note) => (
        <TimelineItem key={note.timestamp}>
          <TimelineOppositeContent>
            <Typography variant="h6">{note.userName}</Typography>
            <Typography variant="h6" color="gray" noWrap>
              on {formatDate(note.timestamp)}
            </Typography>
          </TimelineOppositeContent>

          <TimelineContent>
            <Typography>{note.text}</Typography>
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  );
};

export default CustomTimelineNotes;
