import { Controller } from "stimulus"

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

export default class extends Controller {
  initialize() {

    var chartData = $(this.element).data('chart-data')
    am4core.useTheme(am4themes_animated);
    let chart = am4core.create(this.element, am4charts.XYChart);
    chart.hiddenState.properties.opacity = 0;
    chart.data = chartData['data'];
    chart.logo.disabled = true;

chart.dateFormatter.inputDateFormat = "MMM yyyy";

let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
  dateAxis.renderer.minGridDistance = 60;
  dateAxis.startLocation = 0;
  dateAxis.endLocation = 1;
  dateAxis.baseInterval = {
    timeUnit: "month",
    count: 1
  }

let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
  valueAxis.tooltip.disabled = true;

let agent = chart.series.push(new am4charts.ColumnSeries());
  agent.name = "Agent fault ";
  agent.dataFields.dateX = "month";
  agent.dataFields.valueY = "agent-fault";
  agent.tooltipText = "Agent Fault: [bold]{valueY}[/]";
  agent.tooltip.fontSize = 12
  agent.columns.template.fill = "#ffe9b6"
  agent.stacked = true;
  agent.strokeWidth = 0;

let client = chart.series.push(new am4charts.ColumnSeries());
  client.name = "Client fault ";
  client.dataFields.dateX = "month";
  client.dataFields.valueY = "client-fault";
  client.tooltipText = "Client Fault: [bold]{valueY}[/]";
  client.tooltip.fontSize = 12
  client.columns.template.fill = "#aed8f7"
  client.stacked = true;
  client.strokeWidth = 0;

let vendor = chart.series.push(new am4charts.ColumnSeries());
  vendor.name = "Vendor fault";
  vendor.dataFields.dateX = "month";
  vendor.dataFields.valueY = "vendor-fault";
  vendor.tooltipText = "Vendor Fault: [bold]{valueY}[/]";
  vendor.tooltip.fontSize = 12
  vendor.columns.template.fill = "#ffb1c0"
  vendor.stacked = true;
  vendor.strokeWidth = 0;

let reperform = chart.series.push(new am4charts.StepLineSeries());
  reperform.name = "Vendor Fault reperform (avg days)";
  reperform.dataFields.dateX = "month";
  reperform.dataFields.valueY = "vendor-fault-reperform-days";
  reperform.tooltipText = "{name}: [bold]{valueY}[/]";
  reperform.tooltip.fontSize = 12;
  reperform.stacked = false;
  reperform.stroke = am4core.color("#ffb1c0");
  reperform.fill = "#ffb1c0"
  reperform.startLocation = 0;
  reperform.strokeWidth = 2;
  reperform.noRisers= true;
  reperform.hiddenInLegend = true;

let client_reperform = chart.series.push(new am4charts.StepLineSeries());
  client_reperform.name = "Agent Fault reperform Days";
  client_reperform.dataFields.dateX = "month";
  client_reperform.dataFields.valueY = "client-fault-reperform-days";
  client_reperform.tooltipText = "{name}: [bold]{valueY}[/]";
  client_reperform.tooltip.fontSize = 12
  client_reperform.stacked = false;
  client_reperform.stroke = am4core.color("#aed8f7");
  client_reperform.fill = "#aed8f7"
  client_reperform.startLocation = 0;
  client_reperform.strokeWidth = 2;
  client_reperform.noRisers = true;
  client_reperform.hiddenInLegend = true;


var bullet = reperform.bullets.push(new am4charts.CircleBullet());
  bullet.fill = am4core.color("white");
  bullet.strokeWidth = 2;

var client_reperform_bullet = client_reperform.bullets.push(new am4charts.CircleBullet());
  client_reperform_bullet.fill = am4core.color("white");
    client_reperform_bullet.strokeWidth = 2;

chart.cursor = new am4charts.XYCursor();
chart.cursor.xAxis = dateAxis;
// chart.scrollbarX = new am4core.Scrollbar();

chart.legend = new am4charts.Legend();
chart.legend.position = "bottom";
chart.legend.fontSize = 11
chart.legend.color = "#414141"


const markerTemplate = chart.legend.markers.template;
      markerTemplate.width = 10;
      markerTemplate.height = 10;

// axis ranges
// let range = dateAxis.axisRanges.create();
// range.date = new Date(2001, 1, 1);
// range.endDate = new Date(2003, 1, 1);
// range.axisFill.fill = chart.colors.getIndex(7);
// range.axisFill.fillOpacity = 0.2;

// range.label.text = "Fines for speeding increased";
// range.label.inside = true;
// range.label.rotation = 90;
// range.label.horizontalCenter = "right";
// range.label.verticalCenter = "bottom";

// let range2 = dateAxis.axisRanges.create();
// range2.date = new Date(2007, 1, 1);
// range2.grid.stroke = chart.colors.getIndex(7);
// range2.grid.strokeOpacity = 0.6;
// range2.grid.strokeDasharray = "5,2";


// range2.label.text = "Motorcycle fee introduced";
// range2.label.inside = true;
// range2.label.rotation = 90;
// range2.label.horizontalCenter = "right";
// range2.label.verticalCenter = "bottom";

  }
}
