import _ from "lodashExtended";
import {
  getAuditType,
  getSubmissionDate,
  getObservationResult
} from "../app/selectors";
import emailAddressCheck from "email-addresses";

const isOnSite = state => {
  return getAuditType(state) == "on_site";
};

const isOnSiteAndPresent = (value, state) => {
  return isOnSite(state) && isPresent(value, state);
};

const isOnSiteAndYes = (value, state) => {
  return isOnSite(state) && isYes(value, state);
};

const isOnSiteAndNo = (value, state) => {
  return isOnSite(state) && isNo(value, state);
};

const isYes = (value, state) => {
  return value === "yes";
};

const isNo = (value, state) => {
  return value === "no";
};

const isBlank = (value, state) => {
  return _.isBlank(value);
};

const isPresent = (value, state) => {
  return _.isPresent(value);
};

const yearsFromSubmit = (date, state) => {
  const submissionDate = getSubmissionDate(state);
  return moment(submissionDate).diff(moment(date), "year");
};

const monthsFromSubmit = (date, state) => {
  const submissionDate = getSubmissionDate(state);
  return moment(submissionDate).diff(moment(date), "month");
};

const matchText = (value, regex, state) => {
  return _.isString(value) && _.isPresent(value.match(regex));
};

const isEmail = (value, state) => {
  return _.isString(value) && emailAddressCheck.parseOneAddress(value);
};

const wasteStreamDecisions = {
  hasStorage: [
    {
      score: 0,
      response: "Site has bulk waste storage",
      autoSelect: ({ hasStorage, ...x }, s) => {
        return isPresent(_.get(hasStorage, "clearlyIdentified"));
      }
    },
    {
      score: 3,
      response: "Bulk storage of has not been reported",
      action:
        "Ensure that you have appropriate storage and disposal of general Waste in place for your practice. It is therefore not possible to assess how you dispose general waste and if the waste is correctly segregated. Refer to HTM 07-01 issued by the Department of Health and the EPR 5.07 issued by the Environment Agency and other guidelines to setup the appropriate waste disposal for your practice",
      autoSelect: ({ hasStorage, ...x }, s) => {
        return hasStorage && isBlank(_.get(hasStorage, "clearlyIdentified"));
      }
    }
  ],
  storedSecurely: [
    {
      score: 0,
      response: "The waste is stored securely",
      autoSelect: ({ storedSecurely, ...x }, s) => {
        return (
          storedSecurely &&
          storedSecurely.containmentWithSecurityRequired.length > 0 &&
          isBlank(storedSecurely.notSecureContainment, s)
        );
      }
    },
    {
      score: 3,
      response: "The waste is not stored securely",
      action:
        "Clinical waste has to be stored securely and not accessible to the public. Please ensure that the relevant requirements for Clinical waste storage are met for your practice. Please refer to HTM 07-01 issued by the Department of Health for guidance",
      autoSelect: ({ storedSecurely, ...x }, s) => {
        return (
          storedSecurely &&
          storedSecurely.containmentWithSecurityRequired.length > 0 &&
          isPresent(storedSecurely.notSecureContainment, s)
        );
      }
    },
    {
      score: 3,
      response:
        "No storage reported for clinical waste, storage security cannot be assessed",
      action:
        "Ensure that the relevant requirements for Clinical waste storage are met for your practice. Please refer to HTM 07-01 issued by the Department of Health for guidance",
      autoSelect: ({ storedSecurely, ...x }, s) => {
        return (
          storedSecurely &&
          isBlank(storedSecurely.containmentWithSecurityRequired, s)
        );
      }
    }
  ],
  packagedCorrectly: [
    {
      score: 0,
      response: "Waste is packaged in approved containers",
      autoSelect: ({ packagedCorrectly, ...x }, s) => {
        return (
          packagedCorrectly &&
          packagedCorrectly.withPackagingRequired.length > 0 &&
          isBlank(packagedCorrectly.withoutCorrectPackaging, s)
        );
      }
    },
    {
      score: 3,
      response: "Waste is not packaged in approved containers",
      action:
        "Ensure that your waste is correctly packaged. Refer to HTM 07-01 issued by the Department of Health and the EPR 5.07 issued by the Environment Agency and other guidelines to setup the appropriate waste disposal for your practice",
      autoSelect: ({ packagedCorrectly, ...x }, s) => {
        return (
          packagedCorrectly &&
          packagedCorrectly.withPackagingRequired.length > 0 &&
          isPresent(packagedCorrectly.withoutCorrectPackaging, s)
        );
      }
    },
    {
      score: 3,
      response: "No bulk storage reported, packaging cannot be assessed",
      action:
        "Ensure that your waste is correctly packaged. It is therefore not possible to assess how you dispose waste and if the waste is correctly packaged. Refer to HTM 07-01 issued by the Department of Health and the EPR 5.07 issued by the Environment Agency and other guidelines to setup the appropriate waste disposal for your practice",
      autoSelect: ({ packagedCorrectly, ...x }, s) => {
        return (
          packagedCorrectly &&
          isBlank(packagedCorrectly.withPackagingRequired, s)
        );
      }
    }
  ],
  contaminationFound: [
    {
      score: 0,
      response: "No Contamination found",
      autoSelect: ({ contaminationFound }, s) => {
        return (
          contaminationFound &&
          isBlank(contaminationFound.withContamination, s) &&
          isPresent(contaminationFound.noContamination, s)
        );
      }
    },
    {
      score: 3,
      response:
        "The identified contamination presents a severe risk. See Appendix B for details",
      action:
        "Ensure that the appropriate waste segregation is in place and that bins are available and used for each waste stream. Reiterate training on waste handling and segregation. Add, change or re-position posters at each bin outlining segregation requirement by waste type (What waste type should go in what bin type and what waste types not)",
      autoSelect: ({ contaminationFound }, s) => {
        return contaminationFound && contaminationFound.highestRiskLevel == 3;
      }
    },
    {
      score: 2,
      response:
        "The contamination cannot be fully assesed and therefore presents a potential high risk. See Appendix A for details",
      action:
        "Ensure that the appropriate waste segregation is in place and that bins are available and used for each waste stream. Reiterate training on waste handling and segregation. Add, change or re-position posters at each bin outlining segregation requirement by waste type (What waste type should go in what bin type and what waste types not)",
      autoSelect: ({ contaminationFound }, s) => {
        return (
          contaminationFound &&
          isPresent(contaminationFound.cannotAssesContamination) &&
          contaminationFound.highestRiskLevel < 2
        );
      }
    },
    {
      score: 2,
      response:
        "The identified contamination presents a high risk. See Appendix B for details",
      action:
        "Ensure that the appropriate waste segregation is in place and that bins are available and used for each waste stream. Reiterate training on waste handling and segregation. Add, change or re-position posters at each bin outlining segregation requirement by waste type (What waste type should go in what bin type and what waste types not)",
      autoSelect: ({ contaminationFound }, s) => {
        return contaminationFound && contaminationFound.highestRiskLevel == 2;
      }
    },
    {
      score: 1,
      response:
        "The identified contamination presents a medium risk. See Appendix B for details",
      action:
        "Ensure that the appropriate waste segregation is in place and that bins are available and used for each waste stream. Reiterate training on waste handling and segregation. Add, change or re-position posters at each bin outlining segregation requirement by waste type (What waste type should go in what bin type and what waste types not)",
      autoSelect: ({ contaminationFound }, s) => {
        return contaminationFound && contaminationFound.highestRiskLevel == 1;
      }
    },
    {
      score: 0,
      response:
        "The identified contamination presents a low risk. See Appendix B for details",
      action:
        "Ensure that the appropriate waste segregation is in place and that bins are available and used for each waste stream. Reiterate training on waste handling and segregation. Add, change or re-position posters at each bin outlining segregation requirement by waste type (What waste type should go in what bin type and what waste types not)",
      autoSelect: ({ contaminationFound }, s) => {
        return contaminationFound && contaminationFound.highestRiskLevel == 0;
      }
    },
    {
      score: 3,
      response:
        "Insufficient information provided. The contamination cannot be assesed for this waste stream. See Appendix B for details",
      action:
        "Ensure that the appropriate waste segregation is in place and that bins are available and used for each waste stream. Reiterate training on waste handling and segregation. Add, change or re-position posters at each bin outlining segregation requirement by waste type (What waste type should go in what bin type and what waste types not)",
      autoSelect: ({ contaminationFound }, s) => {
        return (
          contaminationFound &&
          isBlank(contaminationFound.contaminationAssessed)
        );
      }
    },
    {
      score: 3,
      response: "The waste stream has not been audited",
      autoSelect: ({ id }, s) => isBlank(id, s)
    }
  ]
};

const roomDecisions = {
  hasClinicalBins: [
    {
      score: 3,
      response: "Room has not been audited",
      autoSelect: ({ notAudited }, s) => notAudited
    },
    {
      score: 0,
      response: "The room has at least one clinical waste bin identified",
      autoSelect: ({ hasClinicalBins, ...x }, s) => {
        return isPresent(_.get(hasClinicalBins, "clearlyIdentified"));
      }
    },
    {
      score: 3,
      response:
        "No clinical bins have been identified in this room. It is therefore not possible to assess how you dispose any clinical waste and if the waste is correctly segregated. ",
      action:
        "Ensure that the appropriate waste segregation is in place and that bins are available and used for each waste stream. Reiterate training on waste handling and segregation. Add, change or re-position posters at each bin outlining segregation requirement by waste type (What waste type should go in what bin type and what waste types not)",
      autoSelect: ({ hasClinicalBins, ...x }, s) => {
        return (
          hasClinicalBins &&
          isBlank(_.get(hasClinicalBins, "clearlyIdentified"))
        );
      }
    }
  ],
  hasNonClinicalBins: [
    {
      score: 2,
      response: "The room has not been audited",
      autoSelect: ({ notAudited }, s) => notAudited
    },
    {
      score: 0,
      response: "The room has sufficient non clinical waste bins",
      autoSelect: ({ hasNonClinicalBins, ...x }, s) => {
        return isPresent(_.get(hasNonClinicalBins, "clearlyIdentified"));
      }
    },
    {
      score: 2,
      response:
        "No non-clinical waste bins have been identified in this room. It is therefore not possible to assess how you dispose any non clinical waste and if the waste is correctly segregated.",
      action:
        "Ensure that the appropriate waste segregation is in place and that bins are available and used for each waste stream. Reiterate training on waste handling and segregation. Add, change or re-position posters at each bin outlining segregation requirement by waste type (What waste type should go in what bin type and what waste types not)",
      autoSelect: ({ hasNonClinicalBins, ...x }, s) => {
        return (
          hasNonClinicalBins &&
          isBlank(_.get(hasNonClinicalBins, "clearlyIdentified"))
        );
      }
    }
  ],
  allRequiredContainersLabeled: [
    {
      score: 3,
      response: "The room has not been audited",
      autoSelect: ({ notAudited }, s) => notAudited
    },
    {
      score: 0,
      response: "All clinical continers are labelled",
      autoSelect: ({ allRequiredContainersLabeled }, s) => {
        return (
          allRequiredContainersLabeled &&
          allRequiredContainersLabeled.withLabelingRequired.length > 0 &&
          allRequiredContainersLabeled.withClearLabels.length ==
            allRequiredContainersLabeled.withLabelingRequired.length
        );
      }
    },
    {
      score: 3,
      response: "All clinical containers are not labeled",
      action:
        "Ensure that your Clinical Waste Containers are labelled in accordance with the relevant regulatory requirements. Please refer to relevant guidelines such as the HTM 07-01 issued by the Department of Health",
      autoSelect: ({ allRequiredContainersLabeled }, s) => {
        return (
          allRequiredContainersLabeled &&
          allRequiredContainersLabeled.withLabelingRequired.length > 0 &&
          isBlank(allRequiredContainersLabeled.withClearLabels, s)
        );
      }
    },
    {
      score: 2,
      response: "Not all clinical containers are labeled",
      action:
        "Ensure that your Clinical Waste Containers are labelled in accordance with the relevant regulatory requirements. Please refer to relevant guidelines such as the HTM 07-01 issued by the Department of Health",
      autoSelect: ({ allRequiredContainersLabeled }, s) => {
        return (
          allRequiredContainersLabeled &&
          allRequiredContainersLabeled.withLabelingRequired.length > 0 &&
          allRequiredContainersLabeled.withClearLabels.length !=
            allRequiredContainersLabeled.withLabelingRequired.length
        );
      }
    },
    {
      score: 3,
      response: "No clinical containers were audited",
      action:
        "Ensure that your Clinical Waste Containers are labelled in accordance with the relevant regulatory requirements. Please refer to relevant guidelines such as the HTM 07-01 issued by the Department of Health",
      autoSelect: ({ allRequiredContainersLabeled }, s) => {
        return (
          allRequiredContainersLabeled &&
          isBlank(allRequiredContainersLabeled.withLabelingRequired, s)
        );
      }
    }
  ],
  contaminationFound: [
    {
      score: 3,
      response: "The room has not been audited",
      autoSelect: ({ notAudited }, s) => notAudited
    },
    {
      score: 0,
      response: "No Contamination found",
      autoSelect: ({ contaminationFound }, s) => {
        return (
          contaminationFound &&
          isBlank(contaminationFound.withContamination, s) &&
          isPresent(contaminationFound.noContamination, s)
        );
      }
    },
    {
      score: 3,
      response:
        "The identified contamination presents a severe risk. See Appendix A for details",
      action:
        "Ensure that the appropriate waste segregation is in place and that bins are available and used for each waste stream. Reiterate training on waste handling and segregation. Add, change or re-position posters at each bin outlining segregation requirement by waste type (What waste type should go in what bin type and what waste types not)",
      autoSelect: ({ contaminationFound }, s) => {
        return contaminationFound && contaminationFound.highestRiskLevel == 3;
      }
    },
    {
      score: 2,
      response:
        "The contamination cannot be fully assesed and therefore presents a potential high risk. See Appendix A for details",
      action:
        "Ensure that the appropriate waste segregation is in place and that bins are available and used for each waste stream. Reiterate training on waste handling and segregation. Add, change or re-position posters at each bin outlining segregation requirement by waste type (What waste type should go in what bin type and what waste types not)",
      autoSelect: ({ contaminationFound }, s) => {
        return (
          contaminationFound &&
          isPresent(contaminationFound.cannotAssesContamination) &&
          contaminationFound.highestRiskLevel < 2
        );
      }
    },
    {
      score: 2,
      response:
        "The identified contamination presents a high risk. See Appendix A for details",
      action:
        "Ensure that the appropriate waste segregation is in place and that bins are available and used for each waste stream. Reiterate training on waste handling and segregation. Add, change or re-position posters at each bin outlining segregation requirement by waste type (What waste type should go in what bin type and what waste types not)",
      autoSelect: ({ contaminationFound }, s) => {
        return contaminationFound && contaminationFound.highestRiskLevel == 2;
      }
    },
    {
      score: 1,
      response:
        "The identified contamination presents a medium risk. See Appendix A for details",
      action:
        "Ensure that the appropriate waste segregation is in place and that bins are available and used for each waste stream. Reiterate training on waste handling and segregation. Add, change or re-position posters at each bin outlining segregation requirement by waste type (What waste type should go in what bin type and what waste types not)",
      autoSelect: ({ contaminationFound }, s) => {
        return contaminationFound && contaminationFound.highestRiskLevel == 1;
      }
    },
    {
      score: 0,
      response:
        "The identified contamination presents a low risk. See Appendix A for details",
      action:
        "Ensure that the appropriate waste segregation is in place and that bins are available and used for each waste stream. Reiterate training on waste handling and segregation. Add, change or re-position posters at each bin outlining segregation requirement by waste type (What waste type should go in what bin type and what waste types not)",
      autoSelect: ({ contaminationFound }, s) => {
        return contaminationFound && contaminationFound.highestRiskLevel == 0;
      }
    },
    {
      score: 2,
      response:
        "Insufficient information provided. The contamination cannot be assesed in this room and therefore presents a potential high risk. See Appendix A for details",
      action:
        "Ensure that the appropriate waste segregation is in place and that bins are available and used for each waste stream. Reiterate training on waste handling and segregation. Add, change or re-position posters at each bin outlining segregation requirement by waste type (What waste type should go in what bin type and what waste types not)",
      autoSelect: ({ contaminationFound }, s) => {
        return (
          contaminationFound &&
          isBlank(contaminationFound.contaminationAssessed)
        );
      }
    }
  ]
};

const externalBinDecisions = {
  clearlyIdentified: [
    {
      result: true,
      response: "Clear image of the external container provided",
      autoSelect: ({ exteriorPhoto }, s) => isOnSiteAndPresent(exteriorPhoto, s)
    },
    {
      result: false,
      response:
        "No image provided. It is therefore not possible to assess whether the requirement has been met",
      autoSelect: ({ exteriorPhoto }, s) => isBlank(exteriorPhoto, s)
    },
    {
      result: false,
      response:
        "The image does not clearly show the external container. It is therefore not possible to assess whether the requirement has been met"
    }
  ],
  contentsClearlyShown: [
    {
      result: true,
      response: "Clear image of the contents provided",
      autoSelect: ({ interiorPhoto }, s) => isOnSiteAndPresent(interiorPhoto, s)
    },
    {
      result: false,
      response:
        "No image provided. It is therefore not possible to assess whether the requirement has been met",
      autoSelect: ({ interiorPhoto }, s) => isBlank(interiorPhoto, s)
    },
    {
      result: false,
      response:
        "The image does not clearly show the external container. It is therefore not possible to assess whether the requirement has been met"
    }
  ],
  packagedCorrectly: [
    {
      result: true,
      response: "Waste is packaged in approved containers",
      autoSelect: ({ contaminants }, s) => {
        return isOnSite(s) && !_.get(contaminants, "unpackagedWaste");
      }
    },
    {
      result: false,
      response: "Waste not packaged in approved containers",
      autoSelect: ({ contaminants }, s) => {
        return !!_.get(contaminants, "unpackagedWaste");
      }
    },
    {
      result: false,
      response: "No image provided. It is not possible to assess the packaging",
      autoSelect: ({ interiorPhoto }, s) => isBlank(interiorPhoto, s)
    }
  ],
  isBinLocked: [
    {
      result: true,
      response: "The containers are locked",
      autoSelect: ({ isBinLocked }, s) => isOnSiteAndYes(isBinLocked, s)
    },
    {
      result: false,
      response: "The containers are not locked",
      autoSelect: ({ isBinLocked }, s) => isNo(isBinLocked, s)
    },
    {
      result: false,
      response:
        "No Response, it is not possible to assess if the container is secure",
      autoSelect: ({ isBinLocked }, s) => isBlank(isBinLocked, s)
    }
  ],
  isAreaLocked: [
    {
      result: true,
      response: "The area is secure",
      autoSelect: ({ isAreaLocked }, s) => isOnSiteAndYes(isAreaLocked, s)
    },
    {
      result: false,
      response: "The area is not secure",
      autoSelect: ({ isAreaLocked }, s) => isNo(isAreaLocked, s)
    },
    {
      result: false,
      response:
        "No response, it is not possible to assess if the area is secure",
      autoSelect: ({ isAreaLocked }, s) => isBlank(isAreaLocked, s)
    }
  ],
  contaminationFound: [
    {
      result: "none",
      response: "No Contamination found"
    },
    {
      result: 3,
      response: "The identified contamination presents a severe risk"
    },
    {
      result: 2,
      response: "The identified contamination presents a high risk"
    },
    {
      result: 1,
      response: "The identified contamination presents a medium risk"
    },
    {
      result: 0,
      response: "The identified contamination presents a low risk"
    },
    {
      result: "cannot_assess",
      response:
        "No or insufficient information, contamination cannot be assesed."
    }
  ]
};

const bulkContainmentDecisions = {
  clearlyIdentified: [
    {
      result: true,
      response: "Clear image of area provided",
      autoSelect: ({ bulkStorageAreaPhoto }, s) =>
        isOnSiteAndPresent(bulkStorageAreaPhoto, s)
    },
    {
      result: false,
      response:
        "No image provided. It is therefore not possible to assess whether the requirement has been met",
      autoSelect: ({ bulkStorageAreaPhoto }, s) =>
        isBlank(bulkStorageAreaPhoto, s)
    },
    {
      result: false,
      response:
        "The image does not clearly show the area. It is therefore not possible to assess whether the requirement has been met"
    }
  ],
  isPubliclyAccessible: [
    {
      result: true,
      response: "The area is not accessible to the public",
      autoSelect: ({ isPubliclyAccessible }, s) =>
        isOnSiteAndNo(isPubliclyAccessible, s)
    },
    {
      result: false,
      response: "The area is accessible to the public",
      autoSelect: ({ isPubliclyAccessible }, s) =>
        isYes(isPubliclyAccessible, s)
    },
    {
      result: false,
      response: "No Response, The area may be accessible to the public",
      autoSelect: ({ isPubliclyAccessible }, s) =>
        isBlank(isPubliclyAccessible, s)
    }
  ],
  isAreaLocked: [
    {
      result: true,
      response: "The area is secure",
      autoSelect: ({ isAreaLocked }, s) => isOnSiteAndYes(isAreaLocked, s)
    },
    {
      result: false,
      response: "The area is not secure",
      autoSelect: ({ isAreaLocked }, s) => isNo(isAreaLocked, s)
    },
    {
      result: false,
      response: "No response. The area may not be secure",
      autoSelect: ({ isAreaLocked }, s) => isBlank(isAreaLocked, s)
    }
  ],
  packagedCorrectly: [
    {
      result: true,
      response: "Waste is packaged in approved containers",
      autoSelect: ({ contaminants }, s) => {
        return isOnSite(s) && !_.get(contaminants, "unpackagedWaste");
      }
    },
    {
      result: false,
      response: "Waste not packaged in approved containers",
      autoSelect: ({ contaminants }, s) => {
        return !!_.get(contaminants, "unpackagedWaste");
      }
    },
    {
      result: false,
      response: "No image provided. It is not possible to assess the packaging",
      autoSelect: ({ bulkStorageAreaPhoto }, s) =>
        isBlank(bulkStorageAreaPhoto, s)
    }
  ],
  contaminationFound: [
    {
      result: "none",
      response: "No Contamination found"
    },
    {
      result: 3,
      response: "The identified contamination presents a severe risk"
    },
    {
      result: 2,
      response: "The identified contamination presents a high risk"
    },
    {
      result: 1,
      response: "The identified contamination presents a medium risk"
    },
    {
      result: 0,
      response: "The identified contamination presents a low risk"
    },
    {
      result: "cannot_assess",
      response:
        "No or insufficient description, contamination cannot be assesed.",
      autoSelect: ({ bulkStorageAreaPhoto }, s) =>
        isBlank(bulkStorageAreaPhoto, s)
    }
  ]
};

const internalBinDecisions = {
  clearlyIdentified: [
    {
      result: true,
      response: "Clear image of the container provided",
      autoSelect: ({ exteriorPhoto }, s) => isOnSiteAndPresent(exteriorPhoto, s)
    },
    {
      result: false,
      response:
        "No image provided. It is therefore not possible to assess whether the requirement has been met",
      autoSelect: ({ exteriorPhoto }, s) => isBlank(exteriorPhoto, s)
    },
    {
      result: false,
      response:
        "The image does not clearly show the container. It is therefore not possible to assess whether the requirement has been met"
    }
  ],
  clearlyLabelled: [
    {
      result: true,
      response: "Container is clearly labelled",
      autoSelect: ({ hasCorrectLabel }, s) => isOnSiteAndYes(hasCorrectLabel, s)
    },
    {
      result: false,
      response: "Container has not been labelled correctly or clearly",
      autoSelect: ({ hasCorrectLabel }, s) => isNo(hasCorrectLabel, s)
    },
    {
      result: false,
      response: "No image provided, label cannot be verified",
      autoSelect: ({ exteriorPhoto }, s) => isBlank(exteriorPhoto, s)
    },
    {
      result: false,
      response: "Image is not clear enough to verify label"
    }
  ],
  contentsClearlyShown: [
    {
      result: true,
      response: "Clear image of the contents provided",
      autoSelect: ({ interiorPhoto }, s) => isOnSiteAndPresent(interiorPhoto, s)
    },
    {
      result: false,
      response:
        "No image provided. It is therefore not possible to assess whether the requirement has been met",
      autoSelect: ({ interiorPhoto }, s) => isBlank(interiorPhoto, s)
    },
    {
      result: false,
      response:
        "The image does not clearly show the contents of the container. It is therefore not possible to assess whether the requirement has been met"
    }
  ],
  contaminationFound: [
    {
      result: "none",
      response: "No Contamination found"
    },
    {
      result: 3,
      response: "The identified contamination presents a severe risk"
    },
    {
      result: 2,
      response: "The identified contamination presents a high risk"
    },
    {
      result: 1,
      response: "The identified contamination presents a medium risk"
    },
    {
      result: 0,
      response: "The identified contamination presents a low risk"
    },
    {
      result: "cannot_assess",
      response:
        "No or insufficient information, contamination cannot be assesed.",
      autoSelect: ({ interiorPhoto }, s) => isBlank(interiorPhoto, s)
    }
  ]
};

var decisionData = {
  sections: {
    general_information: {
      email: [
        {
          score: 0,
          response: "Information provided",
          autoSelect: ({ email }, s) => isEmail(email, s)
        },
        {
          score: 0,
          response:
            "Insufficient details provided, please ensure that the information is provided in full as part of your next audit",
          autoSelect: ({ email }, s) => !isEmail(email, s)
        },
        {
          score: 0,
          response:
            "Details not provided, please ensure that the information is provided in full as part of your next audit",
          autoSelect: ({ email }, s) => isBlank(email, s)
        }
      ],
      clinicalEmployeeCount: [
        {
          score: 0,
          response: "Information provided",
          autoSelect: ({ clinicalEmployeeCount }, s) =>
            isPresent(clinicalEmployeeCount, s)
        },
        {
          score: 0,
          response:
            "Details not provided, please ensure that the information is provided in full as part of your next audit",
          autoSelect: ({ clinicalEmployeeCount }, s) =>
            isBlank(clinicalEmployeeCount, s)
        }
      ],
      clinicallyActiveRoomsCount: [
        {
          score: 0,
          response: "Information provided",
          autoSelect: ({ clinicallyActiveRoomsCount }, s) =>
            isPresent(clinicallyActiveRoomsCount, s)
        },
        {
          score: 0,
          response:
            "Details not provided, please ensure that the information is provided in full as part of your next audit",
          autoSelect: ({ clinicallyActiveRoomsCount }, s) =>
            isBlank(clinicallyActiveRoomsCount, s)
        }
      ],
      jobTitle: [
        {
          score: 0,
          response: "Information provided",
          autoSelect: ({ jobTitle }, s) => isOnSiteAndPresent(jobTitle, s)
        },
        {
          score: 0,
          response:
            "Insufficient details provided, please ensure that the information is provided in full as part of your next audit"
        },
        {
          score: 0,
          response:
            "Details not provided, please ensure that the information is provided in full as part of your next audit",
          autoSelect: ({ jobTitle }, s) => isBlank(jobTitle, s)
        }
      ],
      hasKnowledgeOfHtm0701: [
        {
          score: 0,
          response:
            "The person conducting the audit has working knowledge of HTM 07-01",
          autoSelect: ({ hasKnowledgeOfHtm0701 }, s) =>
            isYes(hasKnowledgeOfHtm0701, s)
        },
        {
          score: 3,
          response:
            "The person conducting the audit does not have a working knowledge of HTM 07-01",
          action:
            "Familiarise yourself with the Health Technical Memorandum 07-01: Safe management of healthcare waste (HTM 07-01) issued by the Department of Health",
          autoSelect: ({ hasKnowledgeOfHtm0701 }, s) =>
            isNo(hasKnowledgeOfHtm0701, s)
        },
        {
          score: 3,
          response: "Details not provided",
          action:
            "Familiarise yourself with the Health Technical Memorandum 07-01: Safe management of healthcare waste (HTM 07-01) issued by the Department of Health",
          autoSelect: ({ hasKnowledgeOfHtm0701 }, s) =>
            isBlank(hasKnowledgeOfHtm0701, s)
        }
      ],
      hasKnowledgeOfEpr507: [
        {
          score: 0,
          response:
            "The person conducting the audit has working knowledge of EPR 5.07",
          autoSelect: ({ hasKnowledgeOfEpr507 }, s) =>
            isYes(hasKnowledgeOfEpr507, s)
        },
        {
          score: 3,
          response:
            "The person conducting the audit does not have a working knowledge of EPR 5.07",
          action:
            "Familiarise yourself with the additional guidance for Clinical Waste (EPR 5.07) on how to comply with the environmental permit requirements of your waste disposal company.",
          autoSelect: ({ hasKnowledgeOfEpr507 }, s) =>
            isNo(hasKnowledgeOfEpr507, s)
        },
        {
          score: 3,
          response: "Details not provided",
          action:
            "Familiarise yourself with the additional guidance for Clinical Waste (EPR 5.07) on how to comply with the environmental permit requirements of your waste disposal company.",
          autoSelect: ({ hasKnowledgeOfEpr507 }, s) =>
            isBlank(hasKnowledgeOfEpr507, s)
        }
      ],
      fullName: [
        {
          score: 0,
          response: "Information provided",
          autoSelect: ({ fullName }, s) => isOnSiteAndPresent(fullName, s)
        },
        {
          score: 0,
          response:
            "Details not provided, please ensure that the information is provided in full as part of your next audit",
          autoSelect: ({ fullName }, s) => isBlank(fullName, s)
        },
        {
          score: 0,
          response:
            "Insufficient details provided, please ensure that the information is provided in full as part of your next audit"
        }
      ]
    },
    waste_management_policy: {
      policyOwnerName: [
        {
          score: 0,
          response: "Information provided",
          autoSelect: ({ policyOwnerName }, s) =>
            isOnSiteAndPresent(policyOwnerName, s)
        },
        {
          score: 0,
          response:
            "Details not provided, please ensure that the information is provided in full as part of your next audit",
          autoSelect: ({ policyOwnerName }, s) => isBlank(policyOwnerName, s)
        },
        {
          score: 0,
          response:
            "Insufficient details provided, please ensure that the information is provided in full as part of your next audit"
        }
      ],
      policyDocument: [
        {
          score: 0,
          response: "Policy attached",
          autoSelect: ({ policyDocument }, s) =>
            isOnSiteAndPresent(policyDocument, s)
        },
        {
          score: 2,
          response:
            "The attachment is not clearly visible to verify the question, it is therefore not possible to assess whether the requirement has been met. ",
          action:
            "Refer to the HTM 07-01 issued by the Department of Health and other relevant guidance to set a Waste Management Policy for your practice"
        },
        {
          score: 2,
          response:
            "The attachment provided does not correspond to the requirement for this question, It is therefore not possible to assess whether the requirement has been met",
          action:
            "Refer to the HTM 07-01 issued by the Department of Health and other relevant guidance to set a Waste Management Policy for your practice"
        },
        {
          score: 2,
          response:
            "An attachment has not been provided, it is therefore not possible to assess whether the requirement has been met",
          action:
            "Refer to the HTM 07-01 issued by the Department of Health and other relevant guidance to set a Waste Management Policy for your practice",
          autoSelect: ({ policyDocument }, s) => isBlank(policyDocument, s)
        }
      ],
      hasWasteManagementPolicy: [
        {
          score: 0,
          response: "Practice has a policy",
          autoSelect: ({ hasWasteManagementPolicy }, s) =>
            isYes(hasWasteManagementPolicy, s)
        },
        {
          score: 2,
          response:
            "Practice has no policy, a Waste Management Policy need to be in place for your practice.",
          action:
            "Refer to the HTM 07-01 issued by the Department of Health and other relevant guidance to set a Waste Management Policy for your practice",
          autoSelect: ({ hasWasteManagementPolicy }, s) =>
            isNo(hasWasteManagementPolicy, s)
        },
        {
          score: 2,
          response:
            "Not Answered, A Waste Management Policy need to be in place for your practice.",
          action:
            "Refer to the HTM 07-01 issued by the Department of Health and other relevant guidance to set a Waste Management Policy for your practice",
          autoSelect: ({ hasWasteManagementPolicy }, s) =>
            isBlank(hasWasteManagementPolicy, s)
        }
      ],
      policyLastReviewDate: [
        {
          score: 0,
          response: "Date provided less than 1 year old",
          autoSelect: ({ policyLastReviewDate }, state) =>
            isOnSite(state) && yearsFromSubmit(policyLastReviewDate, state) < 1
        },
        {
          score: 1,
          response: "Policy is older than 1 Year, your policy is out of date",
          action:
            "Review and update your policy. the policy needs to be reviewed and updated every year",
          autoSelect: ({ policyLastReviewDate }, state) =>
            isOnSite(state) && yearsFromSubmit(policyLastReviewDate, state) >= 1
        },
        {
          score: 0,
          response:
            "Date provided does not match attachment but is within 1 year"
        },
        {
          score: 1,
          response:
            "Date does not match attachment and date on attachment is older than 1 year, your policy is out of date",
          action:
            "Review and update your policy. the policy needs to be reviewed and updated every year"
        },
        {
          score: 1,
          response:
            "Details not provided, it is therefore not possible to assess whether the requirement has been met",
          action:
            "Review and update your policy. The policy needs to be reviewed and updated every year",
          autoSelect: ({ policyLastReviewDate }, s) =>
            isBlank(policyLastReviewDate, s)
        },
        {
          score: 1,
          response:
            "No attachment provided. It is therefore not possible to assess whether the date provided matches the relevant policy",
          action:
            "Review and update your policy. The policy needs to be reviewed and updated every year",
          autoSelect: ({ policyDocument }, s) => isBlank(policyDocument, s)
        }
      ]
    },
    waste_management_training: {
      trainingMethod: [
        {
          score: 2,
          response: "Training is not provided",
          action:
            "Ensure that you have suitable training methods in place at your practice",
          autoSelect: ({ doesWasteManagementTraining }, s) =>
            isNo(doesWasteManagementTraining, s)
        },
        {
          score: 0,
          response: "Training is provided using suitable methods",
          autoSelect: ({ trainingMethod }, s) =>
            isPresent(_.compact(Object.values(trainingMethod || {})), s)
        },
        {
          score: 2,
          response: "No information on your training methods provided",
          action:
            "Ensure that you have suitable training methods in place at your practice",
          autoSelect: ({ trainingMethod }, s) =>
            isBlank(_.compact(Object.values(trainingMethod || {})), s)
        }
      ],
      doesWasteManagementTraining: [
        {
          score: 0,
          response: "Training provided",
          autoSelect: ({ doesWasteManagementTraining }, s) =>
            isYes(doesWasteManagementTraining, s)
        },
        {
          score: 2,
          response:
            "Training is not provided. Your practice is required to deliver Waste Management training to all staff",
          action:
            "Setup training material and a training schedule for all your staff. Please refer to the HTM 07-01 issued by the Department of Health for guidance",
          autoSelect: ({ doesWasteManagementTraining }, s) =>
            isNo(doesWasteManagementTraining, s)
        },
        {
          score: 2,
          response:
            "No Response. Your practice is required to deliver Waste Management training to all staff",
          action:
            "Setup training material and a training schedule for all your staff. Please refer to the HTM 07-01 issued by the Department of Health for guidance",
          autoSelect: ({ doesWasteManagementTraining }, s) =>
            isBlank(doesWasteManagementTraining, s)
        }
      ],
      hasWasteManagementTrainingRecords: [
        {
          score: 2,
          response: "Training is not provided",
          action: "Ensure that you setup and maintain training records",
          autoSelect: ({ doesWasteManagementTraining }, s) =>
            isNo(doesWasteManagementTraining, s)
        },
        {
          score: 0,
          response: "Practice keeps training records",
          autoSelect: ({ hasWasteManagementTrainingRecords }, s) =>
            isYes(hasWasteManagementTrainingRecords, s)
        },
        {
          score: 2,
          response:
            "No training records kept. You are required to keep training records of all staff",
          action: "Ensure that you setup and maintain training records",
          autoSelect: ({ hasWasteManagementTrainingRecords }, s) =>
            isNo(hasWasteManagementTrainingRecords, s)
        },
        {
          score: 2,
          response:
            "No response. You are required to keep training records of all staff",
          action: "Ensure that you setup and maintain training records",
          autoSelect: ({ hasWasteManagementTrainingRecords }, s) =>
            isBlank(hasWasteManagementTrainingRecords, s)
        }
      ],
      whenIsTrainingDone: [
        {
          score: 0,
          response: "Staff receive induction, ad-hoc and regular training",
          autoSelect: ({ whenIsTrainingDone }, s) => {
            return (
              whenIsTrainingDone &&
              isPresent(whenIsTrainingDone.regularly, s) &&
              whenIsTrainingDone.atInduction &&
              whenIsTrainingDone.adHoc
            );
          }
        },
        {
          score: 2,
          response: "Training is not provided",
          action:
            "Ensure that training is provided at induction, on a regular basis and ad-hoc if required to all staff",
          autoSelect: ({ doesWasteManagementTraining }, s) => {
            return isNo(doesWasteManagementTraining, s);
          }
        },
        {
          score: 2,
          response: "No response provided",
          action:
            "Ensure that training is provided at induction, on a regular basis and ad-hoc if required to all staff",
          autoSelect: ({ whenIsTrainingDone }, s) => {
            return (
              isBlank(whenIsTrainingDone, s) ||
              (!whenIsTrainingDone.atInduction &&
                isBlank(whenIsTrainingDone.regularly, s) &&
                !whenIsTrainingDone.adHoc)
            );
          }
        },
        {
          score: 2,
          response: "Only induction training provided",
          action:
            "Ensure that training is also provided on a regular basis and ad-hoc if required to all staff",
          autoSelect: ({ whenIsTrainingDone }, s) => {
            return (
              whenIsTrainingDone &&
              whenIsTrainingDone.atInduction &&
              isBlank(whenIsTrainingDone.regularly, s) &&
              !whenIsTrainingDone.adHoc
            );
          }
        },
        {
          score: 0,
          response: "Staff receive only induction and ad-hoc training",
          autoSelect: ({ whenIsTrainingDone }, s) => {
            return (
              whenIsTrainingDone &&
              whenIsTrainingDone.atInduction &&
              isBlank(whenIsTrainingDone.regularly, s) &&
              whenIsTrainingDone.adHoc
            );
          }
        },
        {
          score: 0,
          response: "Staff receive only induction and regular training",
          autoSelect: ({ whenIsTrainingDone }, s) => {
            return (
              whenIsTrainingDone &&
              isPresent(whenIsTrainingDone.regularly, s) &&
              whenIsTrainingDone.atInduction &&
              !whenIsTrainingDone.adHoc
            );
          }
        },
        {
          score: 2,
          response: "Induction training not provided",
          action:
            "Ensure that induction training is also provided to every new staff member",
          autoSelect: ({ whenIsTrainingDone }, s) => {
            return whenIsTrainingDone && !whenIsTrainingDone.atInduction;
          }
        }
      ],
      trainingProvidedTo: [
        {
          score: 2,
          response: "Training is not provided",
          action:
            "Setup training material and a training schedule for all your staff",
          autoSelect: ({ doesWasteManagementTraining }, s) =>
            isNo(doesWasteManagementTraining, s)
        },
        {
          score: 0,
          response: "Training provided to all staff",
          autoSelect: ({ trainingProvidedTo }, s) =>
            matchText(trainingProvidedTo, /all staff/i, s)
        },
        {
          score: 1,
          response:
            "Training is provided to clinical staff only. Your practice is required to deliver Waste Management training to all staff.",
          action:
            "Setup training material and a training schedule for all your staff",
          autoSelect: ({ trainingProvidedTo }, s) =>
            matchText(trainingProvidedTo, /clinical staff only/i, s)
        },
        {
          score: 2,
          response:
            "No Response. Your practice is required to deliver Waste Management training to all staff.",
          action:
            "Setup training material and a training schedule for all your staff",
          autoSelect: ({ trainingProvidedTo }, s) =>
            isBlank(trainingProvidedTo, s)
        }
      ],
      trainingRecordSample: [
        {
          score: 2,
          response: "Training is not provided",
          action:
            "Ensure that you setup and maintain training records for all staff",
          autoSelect: ({ doesWasteManagementTraining }, s) =>
            isNo(doesWasteManagementTraining, s)
        },
        {
          score: 0,
          response: "Sample of training record provided",
          autoSelect: ({ trainingRecordSample }, s) =>
            isOnSiteAndPresent(trainingRecordSample, s)
        },
        {
          score: 2,
          response:
            "The attachment is not clearly visible to verify the question. It is therefore not possible to assess whether the requirement has been met",
          action:
            "Ensure that you setup and maintain training records for all staff"
        },
        {
          score: 2,
          response:
            "The attachment provided does not correspond to the requirement for this question. It is therefore not possible to assess whether the requirement has been met",
          action:
            "Ensure that you setup and maintain training records for all staff"
        },
        {
          score: 2,
          response:
            "An attachment has not been provided. It is therefore not possible to assess whether the requirement has been met.",
          action:
            "Ensure that you setup and maintain training records for all staff",
          autoSelect: ({ trainingRecordSample }, s) =>
            isBlank(trainingRecordSample, s)
        }
      ],
      hasSegregationSignage: [
        {
          score: 0,
          response: "Signs are used to outline waste segregation",
          autoSelect: ({ hasSegregationSignage }, s) =>
            isYes(hasSegregationSignage, s)
        },
        {
          score: 2,
          response: "Signs are not used to outline waste segregation",
          action:
            "To ensure that each staff member understands what bins should be used for which waste type, signs should be placed on or next to each bin providing an overview of what waste types should and should not be placed in that specific bin",
          autoSelect: ({ hasSegregationSignage }, s) =>
            isNo(hasSegregationSignage, s)
        },
        {
          score: 2,
          response: "No Response",
          action:
            "To ensure that each staff member understands what bins should be used for which waste type, signs should be placed on or next to each bin providing an overview of what waste types should and should not be placed in that specific bin",
          autoSelect: ({ hasSegregationSignage }, s) =>
            isBlank(hasSegregationSignage, s)
        }
      ]
    },
    hwcn_records: {
      hasHwcnRecords: [
        {
          score: 0,
          response: "Records of HCWNs are kept",
          autoSelect: ({ hasHwcnRecords }, s) => isYes(hasHwcnRecords, s)
        },
        {
          score: 2,
          response: "Records of HWCNs are not kept",
          action:
            "Contact your Waste Contractor to provide you with the relevant documentation or access Anenta's Vector system to see if your HWCNs are stored online. Ensure that your practice receives HWCNs with each clinical waste collection",
          autoSelect: ({ hasHwcnRecords }, s) => isNo(hasHwcnRecords, s)
        },
        {
          score: 2,
          response: "No Response",
          action:
            "Contact your Waste Contractor to provide you with the relevant documentation or access Anenta's Vector system to see if your HWCNs are stored online. Ensure that your practice receives HWCNs with each clinical waste collection",
          autoSelect: ({ hasHwcnRecords }, s) => isBlank(hasHwcnRecords, s)
        }
      ],
      providedByFormat: [
        {
          score: 0,
          response: "HWCNs provided in electronic format",
          autoSelect: ({ providedByFormat }, s) =>
            providedByFormat &&
            (providedByFormat.anenta === true ||
              isEmail(providedByFormat.electronic, s))
        },
        {
          score: 0,
          response:
            "HWCNs provided by e-mail but e-mail address cannot be verified",
          autoSelect: ({ providedByFormat }, s) =>
            providedByFormat &&
            isPresent(providedByFormat.electronic, s) &&
            !isEmail(providedByFormat.electronic, s)
        },
        {
          score: 0,
          response: "HWCNs provided in paper format",
          autoSelect: ({ providedByFormat }, s) =>
            providedByFormat && providedByFormat.paper === true
        },
        {
          score: 2,
          response:
            "Format of HWCN not provided. HWCNs need to be provided in either paper or electronic format by the waste contractor",
          action:
            "Ensure that your practice receives HWCNs with each hazardous waste collection",
          autoSelect: ({ providedByFormat }, s) =>
            isBlank(_.compact(Object.values(providedByFormat || {})), s)
        }
      ],
      recentHwcnDocumentDate: [
        {
          score: 0,
          response: "Recent HWCN provided",
          autoSelect: ({ recentHwcnDocumentDate }, state) =>
            isOnSite(state) &&
            monthsFromSubmit(recentHwcnDocumentDate, state) < 1
        },
        {
          score: 2,
          response:
            "The date provided is older than 1 month. Your practice should have more recent HWCNs on file",
          action:
            "Ensure that your practice stores HWCNs with each hazardous waste collection.",
          autoSelect: ({ recentHwcnDocumentDate }, state) =>
            isOnSite(state) &&
            monthsFromSubmit(recentHwcnDocumentDate, state) >= 1
        },
        {
          score: 0,
          response:
            "Date does not match attachment but is less than 1 month old"
        },
        {
          score: 2,
          response:
            "The date provided does not match the attached document, the attached document is older than 1 month. Your practice should have more recent HWCNs on file",
          action:
            "Ensure that your practice stores HWCNs with each hazardous waste collection."
        },
        {
          score: 2,
          response: "A date has not been provided",
          action:
            "Ensure that your practice stores HWCNs with each hazardous waste collection.",
          autoSelect: ({ recentHwcnDocumentDate }, s) =>
            isBlank(recentHwcnDocumentDate, s)
        },
        {
          score: 2,
          response:
            "No attachment provided. It is therefore not possible to assess whether the date provided matches the relevant HWCN ",
          action:
            "Ensure that your practice stores HWCNs with each hazardous waste collection.",
          autoSelect: ({ recentHwcnDocument }, s) =>
            isBlank(recentHwcnDocument, s)
        }
      ],
      recentHwcnDocument: [
        {
          score: 0,
          response: "Correct Information attached or clear Image provided",
          autoSelect: ({ recentHwcnDocument }, s) =>
            isOnSiteAndPresent(recentHwcnDocument, s)
        },
        {
          score: 2,
          response:
            "No Attachment. An attachment has not been provided. It is therefore not possible to assess whether the requirement has been met",
          action:
            "Ensure that your practice receives HWCNs with each hazardous waste collection. Contact your Waste Contractor to provide you with the relevant documentation or access Anenta's Vector system to see if your HWCNs are stored online",
          autoSelect: ({ recentHwcnDocument }, s) =>
            isBlank(recentHwcnDocument, s)
        },
        {
          score: 2,
          response:
            "The attachment is not clearly visible to verify the question. It is therefore not possible to assess whether the requirement has been met",
          action:
            "Ensure that your practice receives HWCNs with each hazardous waste collection. Contact your Waste Contractor to provide you with the relevant documentation or access Anenta's Vector system to see if your HWCNs are stored online"
        },
        {
          score: 2,
          response:
            "Attachment does not match question. The attachment provided does not correspond to the requirement for this question. It is therefore not possible to assess whether the requirement has been met",
          action:
            "Ensure that your practice receives HWCNs with each hazardous waste collection. Contact your Waste Contractor to provide you with the relevant documentation or access Anenta's Vector system to see if your HWCNs are stored online"
        }
      ],
      historicHwcnDocumentDate: [
        {
          score: 0,
          response: "Date provided 3 years or older",
          autoSelect: ({ historicHwcnDocumentDate }, state) =>
            isOnSite(state) &&
            yearsFromSubmit(historicHwcnDocumentDate, state) >= 3
        },
        {
          score: 2,
          response: "Date is less than 3 years old",
          action:
            "Ensure that your practice stores HWCNs with each hazardous waste collection"
        },
        {
          score: 0,
          response:
            "Date is less than 3 years old but is within a month of practice opening"
        },
        {
          score: 0,
          response:
            "Date does not match attachment but is older than 3 years or is within 1 month of practice opening"
        },
        {
          score: 2,
          response:
            "The date provided does not match the attached document, the attached document is less than 3 years old, your practice should have more recent HWCNs on file",
          action:
            "Ensure that your practice stores HWCNs with each hazardous waste collection"
        },
        {
          score: 2,
          response:
            "The date provided does not match the attached document, the attached document is not within 1 month of practice opening, your practice should have more recent HWCNs on file",
          action:
            " Ensure that your practice stores HWCNs with each hazardous waste collection."
        },
        {
          score: 2,
          response:
            "No attachment provided. It is therefore not possible to assess whether the date provided matches the relevant HWCN",
          action:
            "Ensure that your practice stores HWCNs with each hazardous waste collection",
          autoSelect: ({ historicHwcnDocument }, s) =>
            isBlank(historicHwcnDocument, s)
        }
      ],
      historicHwcnDocument: [
        {
          score: 0,
          response: "Correct Information attached or clear Image provided",
          autoSelect: ({ historicHwcnDocument }, s) =>
            isOnSiteAndPresent(historicHwcnDocument, s)
        },
        {
          score: 2,
          response:
            "The attachment is not clearly visible to verify the question. It is therefore not possible to assess whether the requirement has been met",
          action:
            "Ensure that your practice receives HWCNs with each hazardous waste collection. Contact your Waste Contractor to provide you with the relevant documentation or access Anenta's Vector system to see if your HWCNs are stored online"
        },
        {
          score: 2,
          response:
            "Attachment does not match question. The attachment provided does not correspond to the requirement for this question. It is therefore not possible to assess whether the requirement has been met",
          action:
            "Ensure that your practice receives HWCNs with each hazardous waste collection. Contact your Waste Contractor to provide you with the relevant documentation or access Anenta's Vector system to see if your HWCNs are stored online"
        },
        {
          score: 2,
          response:
            "No Attachment. An attachment has not been provided. It is therefore not possible to assess whether the requirement has been met",
          action:
            "Ensure that your practice receives HWCNs with each hazardous waste collection. Contact your Waste Contractor to provide you with the relevant documentation or access Anenta's Vector system to see if your HWCNs are stored online",
          autoSelect: ({ historicHwcnDocument }, s) =>
            isBlank(historicHwcnDocument, s)
        }
      ]
    },
    internal_waste_containment: {
      bins: internalBinDecisions,
      rooms: roomDecisions
    },
    bulk_waste_storage_containment: {
      bins: externalBinDecisions,
      bulkContainments: bulkContainmentDecisions,
      wasteStreams: wasteStreamDecisions
    }
  }
};

export default decisionData;

// export {
//   autoDecide
// }

//"clinical": {
//"hasStorage": [
//{
//"score": 0,
//"response": "Site has bulk waste storage for clinical"
//},
//{
//"score": 3,
//"response": "Bulk storage of clinical waste has not been reported",
//"action": "Ensure that you have appropriate storage and disposal of Clinical Waste in place for your practice. It is therefore not possible to assess how you dispose clinical waste and if the waste is correctly segregated. Refer to HTM 07-01 issued by the Department of Health and the EPR 5.07 issued by the Environment Agency and other guidelines to setup the appropriate waste disposal for your practice"
//}
//],
//"storedSecurely": [
//{
//"score": 0,
//"response": "The clinical waste is stored securely"
//},
//{
//"score": 3,
//"response": "The clinical waste is not stored securely",
//"action": "Action"
//},
//{
//"score": 3,
//"response": "No storage reported for clinical waste, storage security cannot be assessed",
//"action": "Action"
//}
//],
//"packagedCorrectly": [
//{
//"score": 0,
//"response": "Waste is packaged in approved containers"
//},
//{
//"score": 3,
//"response": "Waste not packaged in approved containers",
//"action": "Ensure that your clinical waste is correctly packaged. Refer to HTM 07-01 issued by the Department of Health and the EPR 5.07 issued by the Environment Agency and other guidelines to setup the appropriate waste disposal for your practice"
//},
//{
//"score": 3,
//"response": "No storage reported for clinical waste, packaging cannot be assessed",
//"action": "Ensure that your clinical waste is correctly packaged. It is therefore not possible to assess how you dispose clinical waste and if the waste is correctly packaged. Refer to HTM 07-01 issued by the Department of Health and the EPR 5.07 issued by the Environment Agency and other guidelines to setup the appropriate waste disposal for your practice"
//}
//],
//"contaminationFound": [
//{
//"score": 0,
//"response": "No contamination"
//},
//{
//"score": 2,
//"response": "Contaminated with non clinical waste",
//"action": "Ensure that the appropriate waste segregation is in place and that bins / areas are available and used for each waste stream. Reiterate training on waste handling and segregation. Add, change or re-position posters at each bin / area outlining segregation requirement by waste type (What waste type should go in what bin type / area and what waste type not)"
//},
//{
//"score": 3,
//"response": "There is no storage reported for clinical waste, Contamination cannot be assessed",
//"action": "Ensure that the appropriate waste segregation is in place and that bins / areas are available and used for each waste stream. Reiterate training on waste handling and segregation. Add, change or re-position posters at each bin / area outlining segregation requirement by waste type (What waste type should go in what bin type / area and what waste type not)"
//},
//{
//"score": 3,
//"response": "There is insufficient information provided and contamination cannot be assessed",
//"action": "Ensure that the appropriate waste segregation is in place and that bins / areas are available and used for each waste stream. Reiterate training on waste handling and segregation. Add, change or re-position posters at each bin / area outlining segregation requirement by waste type (What waste type should go in what bin type / area and what waste type not)"
//},
//]
//},
//"general": {
//"hasStorage": [
//{
//"score": 0,
//"response": "Site has bulk waste storage for general",
//"autoSelect": ({hasStorage, ...x}, s) =>  {
//return isPresent(_.get(hasStorage, 'clearlyIdentified'), )
//}
//},
//{
//"score": 3,
//"response": "Bulk storage of general waste has not been reported",
//"action": "Ensure that you have appropriate storage and disposal of general Waste in place for your practice. It is therefore not possible to assess how you dispose general waste and if the waste is correctly segregated. Refer to HTM 07-01 issued by the Department of Health and the EPR 5.07 issued by the Environment Agency and other guidelines to setup the appropriate waste disposal for your practice",
//"autoSelect": ({hasClinicalBins, ...x}, s) =>  {
//return hasStorage && isBlank(_.get(hasStorage, 'clearlyIdentified'))
//}
//}
//],
//"packagedCorrectly": [
//{
//"score": 0,
//"response": "Waste is packaged in approved containers"
//},
//{
//"score": 3,
//"response": "Waste not packaged in approved containers",
//"action": "Ensure that your general waste is correctly packaged. Refer to HTM 07-01 issued by the Department of Health and the EPR 5.07 issued by the Environment Agency and other guidelines to setup the appropriate waste disposal for your practice"
//},
//{
//"score": 3,
//"response": "No storage reported for general waste, packaging cannot be assessed",
//"action": "Ensure that your general waste is correctly packaged. It is therefore not possible to assess how you dispose general waste and if the waste is correctly packaged. Refer to HTM 07-01 issued by the Department of Health and the EPR 5.07 issued by the Environment Agency and other guidelines to setup the appropriate waste disposal for your practice"
//}
//],
//"contaminationFound": [
//{
//"score": 0,
//"response": "No contamination"
//},
//{
//"score": 3,
//"response": "Contaminated with clinical waste",
//"action": "Ensure that the appropriate waste segregation is in place and that bins / areas are available and used for each waste stream. Reiterate training on waste handling and segregation. Add, change or re-position posters at each bin / area outlining segregation requirement by waste type (What waste type should go in what bin type / area and what waste type not)"
//},
//{
//"score": 2,
//"response": "There is no storage reported for general waste, Contamination cannot be assessed",
//"action": "Ensure that the appropriate waste segregation is in place and that bins / areas are available and used for each waste stream. Reiterate training on waste handling and segregation. Add, change or re-position posters at each bin / area outlining segregation requirement by waste type (What waste type should go in what bin type / area and what waste type not)"
//},
//{
//"score": 3,
//"response": "There is insufficient information provided contamination cannot be assessed",
//"action": "Ensure that the appropriate waste segregation is in place and that bins / areas are available and used for each waste stream. Reiterate training on waste handling and segregation. Add, change or re-position posters at each bin / area outlining segregation requirement by waste type (What waste type should go in what bin type / area and what waste type not)"
//},
//]
//},
//"recycling": {
//"hasStorage": [
//{
//"score": 0,
//"response": "Site has bulk waste storage for recycling"
//},
//{
//"score": 3,
//"response": "Bulk storage of recycling waste has not been reported",
//"action": "Ensure that you have appropriate storage and disposal of recycling Waste in place for your practice. It is therefore not possible to assess how you dispose recycling waste and if the waste is correctly segregated. Refer to HTM 07-01 issued by the Department of Health and the EPR 5.07 issued by the Environment Agency and other guidelines to setup the appropriate waste disposal for your practice"
//}
//],
//"contaminationFound": [
//{
//"score": 0,
//"response": "No contamination"
//},
//{
//"score": 3,
//"response": "Contaminated with clinical waste",
//"action": "Ensure that the appropriate waste segregation is in place and that bins / areas are available and used for each waste stream. Reiterate training on waste handling and segregation. Add, change or re-position posters at each bin / area outlining segregation requirement by waste type (What waste type should go in what bin type / area and what waste type not)"
//},
//{
//"score": 2,
//"response": "There is no storage reported for recycling waste, Contamination cannot be assessed",
//"action": "Ensure that the appropriate waste segregation is in place and that bins / areas are available and used for each waste stream. Reiterate training on waste handling and segregation. Add, change or re-position posters at each bin / area outlining segregation requirement by waste type (What waste type should go in what bin type / area and what waste type not)"
//},
//{
//"score": 3,
//"response": "There is insufficient information provided contamination cannot be assessed",
//"action": "Ensure that the appropriate waste segregation is in place and that bins / areas are available and used for each waste stream. Reiterate training on waste handling and segregation. Add, change or re-position posters at each bin / area outlining segregation requirement by waste type (What waste type should go in what bin type / area and what waste type not)"
//},
//]
//},
