import _ from 'lodashExtended'

const getBinDelivery = (id, state) => {
  return state.issues.binDeliveries[id]
}

const getBinDeliveriesForServiceId = (serviceId, state) => {
  return _(state.issues.binDeliveries).filter({ serviceId }).reject({_destroy: '1'}).value()
}

export {
  getBinDelivery,
  getBinDeliveriesForServiceId
}
