import { Box, Divider } from "@mui/material";
import AdhocVisitRequestLog from "service/request-logs/AdhocVisitRequestLog";
import SpecChangeRequestLog from "service/request-logs/SpecChangeRequestLog";
import FailedVisitReportLog from "./FailedVisitReportLog";
import ServiceSuspensionRequestLog from "./ServiceSuspensionRequestLog";
import ServiceResumptionRequestLog from "./ServiceResumptionRequestLog";
import ServiceTerminationRequestLog from "./ServiceTerminationRequestLog";
import ServiceReinstatementRequestLog from "./ServiceReinstatementRequestLog";
import StockDeliveryRequestLog from "./StockDeliveryRequestLog";
import VisitFrequencyChangeRequestLog from "./VisitFrequencyChangeRequestLog";
import ReattemptDateRequestLog from "./ReattemptDateRequestLog";
import ReattemptDateLog from "./ReattemptDateLog";
import RemediationLog from "./RemediationLog";
import NoActionRequiredLog from "./NoActionRequiredLog";

const logsComponents = {
  AdhocVisitRequest: AdhocVisitRequestLog,
  SpecChangeRequest: SpecChangeRequestLog,
  VisitFrequencyChangeRequest: VisitFrequencyChangeRequestLog,
  StockDeliveryRequest: StockDeliveryRequestLog,
  ServiceTerminationRequest: ServiceTerminationRequestLog,
  ServiceReinstatementRequest: ServiceReinstatementRequestLog,
  ServiceSuspensionRequest: ServiceSuspensionRequestLog,
  ServiceResumptionRequest: ServiceResumptionRequestLog,
  FailedVisitReport: FailedVisitReportLog,
  VendorFeedbackOnVisitFailureRecieve: FailedVisitReportLog,
  ClientFeedbackOnVisitFailureRecieve: null,
  ReattemptVisitRequest: ReattemptDateRequestLog,
  ReattemptDateConfirm: ReattemptDateLog,
  RemediationSent: RemediationLog,
  RemediationResponseRejected: RemediationLog,
  RemediationCancelled: RemediationLog,
  RemediationReplyLogged: RemediationLog,
  "No Action Required": NoActionRequiredLog,
};

const LogComponent = ({ eventData, url }) => {
  const getLogComponent = (eventName) => {
    const componentName = Object.keys(logsComponents).find((name) =>
      eventName.includes(name),
    );
    return componentName ? logsComponents[componentName] : null;
  };

  return (
    <>
      {eventData.map((event, index) => {
        const SpecificLogComponent = getLogComponent(event.name);

        return SpecificLogComponent ? (
          <Box key={index}>
            <Divider
              variant="middle"
              sx={{
                m: 0,
                borderColor: "#fff",
                opacity: "0.2",
              }}
            />
            <SpecificLogComponent request={event} url={url} />
          </Box>
        ) : null;
      })}
    </>
  );
};
export default LogComponent;
