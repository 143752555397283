import { Box, Grid } from "@mui/material";
import { useFetch } from "hooks/useFetch";
import _, { map } from "lodash";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useLocation } from "react-router-dom";
import NewMessageActionForm from "service/forms/NewMessageActionForm";
import { TaggingForm } from "service/forms/TaggingForm";
import AppContext from "ticket/AppContext";
import { useGlobalLoadingState, useTicket } from "ticket/selectors";
import ReplyCard from "./ReplyCard";

const findElementWithHighlightedId = (highlightedIds) => {
  const allReplyElements: any = document.querySelectorAll("[id]");
  for (const element of allReplyElements) {
    const ids = element.id.split(",");
    if (highlightedIds?.some((id) => ids.includes(id))) {
      return element;
    }
  }
  return null;
};

const SortedReplies = ({
  replies,
  openFormId,
  setOpenFormId,
  setShowFooterButtons,
  onCloseForm,
  setShowDrawer,
  openFormUrl,
  setOpenFormUrl
}) => {
  const {
    setShowTaggingForm,
    highlightedIds,
    setHighlightedIds,
    setActiveChannel,
    setIsDrawerOpen,
    shouldScroll,
    setShouldScroll,
  } = useContext(AppContext);

  const location = useLocation();
  const globalLoadingState = useGlobalLoadingState();

  const sortedReplies = useMemo(() => {
    return _(replies)
      .groupBy("createdAt")
      .toPairs()
      .sortBy((kvArray) => kvArray[0])
      .map((kvArray) => {
        return _.sortBy(kvArray[1], [
          (reply) => (reply.channel === "vendor" ? 1 : -1),
        ]);
      })
      .value();
  }, [replies]);

  const toggleOpenForm = (id) => {
    setIsDrawerOpen(false);
    setActiveChannel(null);
    setOpenFormId(id);
    setShowFooterButtons(false);
    setShowDrawer(false);
  };

  const closeHandler = () => {
    onCloseForm();
    setOpenFormId(null);
    setShowDrawer(true);
    setShowTaggingForm(false);
  };

  const findElementWithHighlightedId = useCallback((highlightIds) => {
    // console.log("Searching for elements with IDs:", highlightIds);
    const allElements = document.querySelectorAll("[id]");
    // console.log("Total elements in DOM:", allElements.length);

    for (const element of allElements) {
      if (element.id) {
        // console.log("Checking element:", element.id);
        const ids = element.id.split(",");
        if (highlightIds.some((id) => ids.includes(id))) {
          // console.log("Found matching element:", element.id);
          return element;
        }
      }
    }
    // console.log("No matching element found");
    return null;
  }, []);

  const scrollToHighlightedElement = useCallback(
    (highlightIds) => {
      const element = findElementWithHighlightedId(highlightIds);
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "center" });
        // console.log("Scrolled to element:", element.id);
        return true;
      }
      // console.log("Failed to scroll: element not found");
      return false;
    },
    [findElementWithHighlightedId],
  );

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const highlightIdsFromParam = params.getAll("highlight");
    // console.log("Highlight IDs from URL:", highlightIdsFromParam);

    if (highlightIdsFromParam.length > 0 && !globalLoadingState) {
      setHighlightedIds(highlightIdsFromParam);
      setShouldScroll(true);
    }
  }, [location.search, globalLoadingState, setHighlightedIds]);

  useEffect(() => {
    if (shouldScroll && highlightedIds.length > 0) {
      const scrollInterval = setInterval(() => {
        if (scrollToHighlightedElement(highlightedIds)) {
          clearInterval(scrollInterval);
          setShouldScroll(false);
        }
      }, 100);

      return () => clearInterval(scrollInterval);
    }
  }, [shouldScroll, highlightedIds, scrollToHighlightedElement]);

  return (
    <>
      {sortedReplies.map((groupedReplies, key) => (
        <React.Fragment key={key}>
          {groupedReplies.length > 1 ? (
            <ReplyGroup
              replies={groupedReplies}
              toggleOpenForm={toggleOpenForm}
              openFormUrl={openFormUrl}
              openFormId={openFormId}
              setOpenFormUrl={setOpenFormUrl}
              closeHandler={closeHandler}
              highlightedIds={highlightedIds}
            />
          ) : (
            <ReplyItem
              reply={groupedReplies[0]}
              toggleOpenForm={toggleOpenForm}
              openFormUrl={openFormUrl}
              openFormId={openFormId}
              setOpenFormUrl={setOpenFormUrl}
              closeHandler={closeHandler}
              highlightedIds={highlightedIds}
            />
          )}
        </React.Fragment>
      ))}
    </>
  );
};

export default SortedReplies;

const ReplyGroup = ({
  replies,
  toggleOpenForm,
  setOpenFormUrl,
  openFormId,
  openFormUrl,
  closeHandler,
  highlightedIds,
}) => {
  const ticket = useTicket();
  const { showTaggingForm } = useContext(AppContext);
  const organisationReply = _.find(
    replies,
    (reply) => reply.channel === "organisation",
  );
  const vendorReply = _.find(replies, (reply) => reply.channel === "vendor");

  const groupIds = map(replies, (reply) => reply.id).join(",");
  const isGroupHighlighted = highlightedIds?.some((id) =>
    groupIds.includes(id),
  );

  return (
    <Grid
      container
      spacing={2}
      alignItems="stretch"
      sx={{ marginBottom: '20px', }}
      id={groupIds}
    >
      <Grid item xs={6}>
        {organisationReply && (
          <ReplyCard
            selected={isGroupHighlighted}
            reply={organisationReply}
            toggleOpenForm={() => toggleOpenForm(organisationReply.id)}
            setOpenFormUrl={setOpenFormUrl}
          />
        )}
      </Grid>
      <Grid item xs={6}>
        {vendorReply && (
          <ReplyCard
            selected={isGroupHighlighted}
            reply={vendorReply}
            toggleOpenForm={() => toggleOpenForm(vendorReply.id)}
            setOpenFormUrl={setOpenFormUrl}
          />
        )}
      </Grid>
      {replies.map((reply, key) => {
        return (
          <React.Fragment key={key}>
            {openFormId === reply.id && (
              <Box
                key={reply.id}
                sx={{
                  width: "100%",
                  backgroundColor: "#fff",
                  margin: 2,
                  padding: 2,
                  borderRadius: 2,
                  position: "relative",
                  zIndex: 3,
                }}
              >
                {showTaggingForm ? (
                  <TaggingForm
                    formUrl={openFormUrl}
                    onClose={closeHandler}
                    reply={reply}
                  />
                ) : (
                  <NewMessageActionForm
                    formUrl={openFormUrl}
                    onClose={closeHandler}
                    stakeholderContacts={ticket?.stakeholderContacts}
                    reply={reply}
                    setOpenFormUrl={setOpenFormUrl}
                  />
                )}
              </Box>
            )}
          </React.Fragment>
        );
      })}
    </Grid>
  );
};

const ReplyItem = ({
  reply,
  toggleOpenForm,
  setOpenFormUrl,
  openFormId,
  openFormUrl,
  closeHandler,
  highlightedIds,
}) => {
  const ticket = useTicket();
  const { showTaggingForm } = useContext(AppContext);
  const isOrganisation = reply.channel === "organisation";
  const isAgent =
    reply.channel === "agent" && reply.stakeholderName === "Agent";

  const replyIds = reply?.id;
  const isHighlighted = highlightedIds?.some((id) => id == replyIds);

  return (
    <>
      <Box
        style={{
          display: "flex",
          width: "100%",
          justifyContent: isOrganisation ? "flex-start" : "flex-end",
        }}
        key={reply.id}
        id={replyIds}
      >
        <Box
          sx={{
            width: "50%",
            marginLeft: isAgent ? "25%" : "0%",
            marginRight: isAgent ? "25%" : "0%",
            paddingRight: isOrganisation ? "8px" : "0px",
            paddingLeft: !isOrganisation && !isAgent ? "8px" : "0px",
            marginBottom: '20px',
          }}
        >
          <ReplyCard
            selected={isHighlighted}
            reply={reply}
            toggleOpenForm={() => toggleOpenForm(reply.id)}
            setOpenFormUrl={setOpenFormUrl}
          />
        </Box>
      </Box>
      {openFormId === reply.id && (
        <Box
          sx={{
            padding: 2,
            width: "100%",
            backgroundColor: "#fff",
            mb: 2,
            borderRadius: 2,
            position: "relative",
            zIndex: 3,
            ml: isOrganisation ? "0" : "auto",
          }}
        >
          {showTaggingForm ? (
            <TaggingForm
              formUrl={openFormUrl}
              onClose={closeHandler}
              reply={reply}
            />
          ) : (
            <NewMessageActionForm
              formUrl={openFormUrl}
              onClose={closeHandler}
              stakeholderContacts={ticket?.stakeholderContacts}
              reply={reply}
              setOpenFormUrl={setOpenFormUrl}
            />
          )}
        </Box>
      )}
    </>
  );
};
