import CloseIcon from "@mui/icons-material/Close";
import { Alert, Box, Button, Grid, IconButton, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import { map } from "lodash";
import { useContext, useEffect, useState } from "react";
import AppContext from "ticket/AppContext";
import { useTicket } from "ticket/selectors";
import {
  useFormServiceActionMutation,
} from "api/services";
import { useCannedResponses } from "hooks/useCannedResponses";

import { datePickerStyles } from "./common/ServiceCalendarDatePicker";

import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";

import Loader from "../../../components/shared/Loader";

import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/en-gb";
import { formatDate } from "sharedUtils";
import { ActionFormBody, ActionFormTitle } from "./common/ActionFormLayout";

const textForNotice = (requestType, date) => {
  const serviceActionReson = (() => {
      switch (requestType) {
      case 'AdhocVisitRequest': return 'Adhoc Visit';
      case 'FailedVisitReported': return 'Failed Visit Report';
      case 'StockDeliveryRequest': return 'Request Stock Delivery'
      case 'ServiceTerminationRequest': return 'Request Service Termination'
      case 'ServiceSuspensionRequest': return 'Request Service Suspension'
      default: return 'Default value';
      }
  })();

  return `${serviceActionReson} was requested on ${formatDate(date)}, and we have not yet received a response.`;
};

const RemediationForm = () => {
  const [loading, setLoading] = useState(false);

  const ticket = useTicket();
  const { onCloseRemediation, requestedAtDate, eventId, requestType, serviceUuid } =
    useContext(AppContext);
  const [submitForm] = useFormServiceActionMutation();
  const [validationDateError, setValidationDateError] = useState(false);
  const [validationError, setValidationError] = useState(false);
  const [serverError, setServerError] = useState(false);
    const minRemediationDate =
        new Date(requestedAtDate).toDateString() === new Date().toDateString()
            ? requestedAtDate
            : dayjs(requestedAtDate).add(1, "day").toDate().toDateString();

  const [open, setOpen] = useState(false);

  const noticeText = textForNotice(requestType, requestedAtDate);
  const [formData, setFormData] = useState({
    textForNotice: noticeText,
    descriptionOfFailure: "",
    dateOfContractFailure: requestedAtDate,
    vendorMessage: "",
  });

  const { vendorResponse } = useCannedResponses(
    serviceUuid ? `/admin/service_actions/${serviceUuid}/request_remediation.json` : null,
    formData
  );

  useEffect(() => {
    setFormData((formData) => ({
      ...formData,
      vendorMessage: vendorResponse.message,
    }));
  }, [vendorResponse.message]);

  const handleSubmit = () => {
    if (formData.dateOfContractFailure === "Invalid Date") {
      setValidationDateError(true);
      return;
    }

    if (formData.textForNotice === "" || formData.descriptionOfFailure === "" || formData.vendorMessage === "") {
      setValidationError(true);
      return;
    }

    setLoading(true);
    setServerError(false);
    submitForm({
      url: `/admin/service_actions/${serviceUuid}/request_remediation`,
      body: {
        ticket_id: ticket?.id,
        form: {
          vendor_id: ticket?.vendor?.id,
          location_uuid: ticket?.location?.uuid,
          service_uuid: serviceUuid,
          ticket_id: ticket?.id,
          authority_id: ticket?.location?.authority_id,
          date_of_contract_failure: formData.dateOfContractFailure,
          service_action_requested_at: requestedAtDate,
          text_for_notice: formData.textForNotice,
          description_of_failure: formData.descriptionOfFailure,
          service_action_reason: eventId,
          vendor_response_attributes: {
            to_contact_id: vendorResponse.toContactId,
            response_expected: vendorResponse.responseExpected,
            message: formData.vendorMessage,
          },
        },
      },
    })
      .unwrap()
      .then(onCloseRemediation)
      .catch((error) => {
        setLoading(false);
        setServerError(true);
        console.log(error);
      });
  };

  const loadingStyles = {
    position: "absolute",
    inset: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(255, 255, 255, 0.5)",
    backdropFilter: "blur(3px)",
    zIndex: 1000,
  } as React.CSSProperties;

  const body = (
    <>
      {loading && (
        <div style={loadingStyles}>
          <Loader message="Creating remediation..." />
        </div>
      )}
      <Box>
        <Grid
          container
          spacing={2}
          sx={{
            "& .MuiInputBase-input": {
              fontSize: "12px",
            },
            "& .MuiGrid-item:first-of-type": {
              pl: 1,
            },
          }}
        >
          <Grid item xs={6}>
            <Box
              sx={{
                padding: "8px",
                marginBottom: "6px",
                border: "1px solid #F1F1F5",
                borderRadius: "4px",
              }}
            >
              <Box sx={{ mb: "10px" }}>
                <Typography> Contract Failure Date</Typography>

                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                  <DatePicker
                    slotProps={{
                      calendarHeader: datePickerStyles.calendarHeader,
                      textField: {
                        ...datePickerStyles.textField,
                        onClick: () => setOpen(true),
                      },
                      layout: datePickerStyles.layout,
                    }}
                    open={open}
                    onChange={(newValue) => {
                      setValidationDateError(false),
                        setFormData({
                          ...formData,
                          dateOfContractFailure: newValue.format("DD/MM/YYYY"),
                        });
                    }}
                    onClose={() => setOpen(false)}
                    minDate={dayjs(minRemediationDate)}
                    maxDate={dayjs(new Date())}
                    value={dayjs(new Date().toDateString())}
                  />
                </LocalizationProvider>

                {validationDateError && (
                  <Alert severity="error" sx={{ fontSize: "12px", marginBottom: "16px" }}>
                    Please select a valid date
                  </Alert>
                )}
                {validationError && (
                  <Alert severity="error" sx={{ fontSize: "12px", marginBottom: "16px" }}>
                    Please fill in all fields
                  </Alert>
                )}
                {serverError && (
                  <Alert severity="error" sx={{ fontSize: "12px", marginBottom: "16px" }}>
                    Server error, please try again
                  </Alert>
                )}

                {false && (
                  <Alert
                    icon={false}
                    variant="outlined"
                    severity="error"
                    sx={{
                      color: "#323B44",
                      "& .MuiAlert-message": { p: 0 },
                    }}
                  >
                    Warning, please check with a manager that this is ok to send, it may be to soon to remediate!
                  </Alert>
                )}
              </Box>

              <Typography>Text for notice under: 'Vendor has failed to provide service as set out below'</Typography>
              <TextField
                required
                error={validationError && formData.textForNotice === ""}
                multiline
                fullWidth
                rows={4}
                onChange={(e) => {
                  setValidationError(false);
                  setFormData({
                    ...formData,
                    textForNotice: e.target.value,
                  });
                }}
                defaultValue={noticeText}
                sx={{ mb: "10px" }}
              />

              <Typography>Text for notice in table: 'Description of Failure'</Typography>

              <TextField
                required
                fullWidth
                multiline
                rows={4}
                error={validationError && formData.descriptionOfFailure === ""}
                onChange={(e) => {
                  setValidationError(false);
                  setFormData({
                    ...formData,
                    descriptionOfFailure: e.target.value,
                  });
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Stack
              direction="column"
              spacing={0}
              sx={{
                justifyContent: "flex-start",
                alignItems: "stretch",
                padding: "8px",
                marginBottom: "6px",
                border: "2px solid #A42435",
                borderRadius: "4px",
                backgroundColor: "#EEEEEE",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <Typography sx={{ fontSize: "12px", marginBottom: "6px" }}>
                  Send Message to{" "}
                  <Typography
                    component="span"
                    sx={{
                      color: "#A42435",
                      fontWeight: "700",
                    }}
                  >
                    Vendor
                  </Typography>
                </Typography>
              </Box>

              <Select size="small" value={ticket?.stakeholderContacts["vendor"][0]?.id} sx={{ marginBottom: "6px" }}>
                {map(ticket?.stakeholderContacts?.["vendor"], (contact: any) => (
                  <MenuItem key={contact.id} value={contact.id}>
                    {contact.name}
                  </MenuItem>
                ))}
              </Select>

              <TextField
                size="small"
                multiline
                fullWidth
                required
                defaultValue={vendorResponse.message}
                error={validationError && formData.vendorMessage === ""}
                onChange={(e) => {
                  setValidationError(false);
                  setFormData({
                    ...formData,
                    vendorMessage: e.target.value,
                  });
                }}
                InputProps={{
                  sx: { height: "100%", backgroundColor: "#fff" },
                }}
                sx={{ flex: 1 }}
              />

              <Box sx={{ display: "flex", justifyContent: "end", mt: "6px" }}>
                This message requires a response from the <strong> Vendor</strong>
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </>
  );

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          position: "absolute",
          top: "2px",
          right: "0px",
        }}
      >
        <IconButton sx={{ color: "#fff" }} onClick={() => onCloseRemediation()}>
          <CloseIcon />
        </IconButton>
      </Box>

      <ActionFormTitle content={<strong>Send Remediation Notice</strong>} />

      <ActionFormBody content={body} />

      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        spacing={1}
        sx={{
          width: "100%",
          backgroundColor: "#637381",
          padding: "10px",
          border: "1px solid #646D78",
          borderTop: "none",
          borderBottomRightRadius: "10px",
          borderBottomLeftRadius: "10px",
        }}
      >
        <Box sx={{ flex: 1 }} />
        <Button
          variant="outlined"
          color="info"
          onClick={() => onCloseRemediation()}
          sx={{
            fontSize: "12px",
            width: "150px",
            color: "#fff",
            borderColor: "#868d93",
            "&:hover": {
              backgroundColor: "#EEEEEE",
            },
          }}
        >
          Cancel
        </Button>
        <Box>
          <Button
            type="submit"
            variant="contained"
            onClick={handleSubmit}
            color="success"
            sx={{ fontSize: "12px", width: "200px" }}
          >
            Submit
          </Button>
        </Box>
      </Stack>
    </>
  );
};

export default RemediationForm;
