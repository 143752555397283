import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, TableCell, TableRow, Typography, IconButton, Divider, Collapse } from "@mui/material";
import { map, startCase } from "lodash";
import { useState } from "react";
import BinDot from "service/BinDot";
import LogActionButton from "service/forms/VoidFormDialog";
import { DeliveryTable } from "service/DeliveryInstruction";
import { formatDate, useBinFetch } from "sharedUtils";
import ReportLogLayout from './ReportLogLayout';

const StockDeliveryRequestLog = ({ request }) => {
  const { binTypes, binGroups } = useBinFetch();
  const { date, deliveryUnits, notes, createdAt } = request.data || {};
  const [open, setOpen] = useState(false);
  const deliveryRows = map(deliveryUnits, ({ serviceCode, quantity }) => {
    const binType = binTypes[serviceCode];
    const binGroup = binGroups[binType?.binGroupId];

    return (
      <TableRow key={serviceCode}>
        <TableCell scope="row">
          <BinDot
            binGroup={binGroup}
            tooltipName={true}
            tooltipPlace="top"
            showImg
            {...binType}
          />
        </TableCell>
        <TableCell>{binType?.name}</TableCell>
        <TableCell>{serviceCode}</TableCell>
        <TableCell>{quantity}</TableCell>
      </TableRow>
    );
  });

  const inputtedBy = request?.metadata?.inputtedById;

  const logHeader = (
    <>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <>
          {date
            ? `${startCase(request.name)} for ${formatDate(date)}`
            : startCase(request.name)}
        </>
        <Box flex="1" />
      </Box>

      {open && (
        <Divider
          variant="middle"
          sx={{
            m: 0,
            mx: -2,
            mb: 1,
            borderColor: "#fff",
            opacity: "0.2",
          }}
        />
      )}
    </>
  );

  const logContent = (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex", flex: 1, justifyContent: "flex-end" }}>
          <LogActionButton event={request} />
        </Box>
      </Box>
      {deliveryUnits && <DeliveryTable rows={deliveryRows} label={false} />}
      {notes && (
        <Typography variant="h6" sx={{ my: 1 }}>
          {notes}
        </Typography>
      )}
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mt: 2 }}>
        <Typography color="grey" sx={{ fontSize: "10px" }}>
          Inputted by {inputtedBy?.nameWithoutEmail} on{" "}
          {formatDate(createdAt)}
        </Typography>
      </Box>
    </>
  );

  return (
    <ReportLogLayout
      logHeader={logHeader}
      logContent={logContent}
      notes2={notes}
      createdAt={createdAt}
      object={request}
    />
  );
};

export default StockDeliveryRequestLog;
