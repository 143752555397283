import { Divider, ListItemButton } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

const ShowMoreDivider = ({ toggleOpen, isOpen }) => {
  return (
    <ListItemButton
      onClick={toggleOpen}
      disableRipple
      sx={{
        p: 0,
        width: "100%",
        fontWeight: "bold",
        "&:hover": {
          backgroundColor: "transparent",
        },
      }}
    >
      <Divider
        sx={{
          flex: 1,
          p: 0,
          m: 0,
          color: "#fff",
          "&::before": {
            borderTop: "3px solid #323B45",
          },
          "&::after": {
            borderTop: "3px solid #323B45",
          },
        }}
      >
        Show {isOpen ? "less" : "more"}
        {isOpen ? <ExpandLess /> : <ExpandMore />}
      </Divider>
    </ListItemButton>
  );
};

export default ShowMoreDivider;
