import { FC, memo } from "react";
import BinDot from "service/BinDot";
import { groupBinsByGroup } from "../utils";
import { useBinFetch, useVendorServiceMappingsFetch } from "sharedUtils";
import { Box, Typography } from "@mui/material";
import {
  HWCNIcon,
  InvoiceIcon,
  ServiceIcon,
} from "service/forms/common/VisitIcons";
import { isEmpty } from "lodash";

interface VisitTileProps {
  visit: any;
}

const VisitTile: FC<VisitTileProps> = memo(({ visit }) => {
  const { status, vendorServiceCodes } = visit;

  const { binTypes, binGroups, isLoading: loading } = useBinFetch();

  const vendorServiceMappings = useVendorServiceMappingsFetch(
    visit.vendorId,
    vendorServiceCodes
  );

  const grouped = loading
    ? []
    : groupBinsByGroup(vendorServiceCodes, vendorServiceMappings, binTypes);

  return (
    <Box
      className={`visit visit-${status}`}
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        "& .MuiTypography-root": {
          fontSize: "12px",
        },
      }}
    >
      <Box>
        <Typography fontWeight="bold" sx={{ color: "#fff" }}>
          {visit?.vendorName}
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography sx={{ color: "grey", fontSize: "10px !importat" }}>
            {visit?.title}
          </Typography>
          <Box>
            {visit?.hasInvoice && <InvoiceIcon />}
            {visit?.hasHwcn && <HWCNIcon />}
            {!isEmpty(visit?.serviceReportSources) && <ServiceIcon />}
          </Box>
        </Box>
      </Box>

      {grouped && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "2px",
            flexWrap: "wrap",
          }}
        >
          {Object.entries(grouped).map(([groupId], index) => (
            <BinDot
              key={index}
              binGroup={binGroups[groupId]}
              tooltipName={true}
              tooltipPlace="top"
            />
          ))}
        </Box>
      )}
    </Box>
  );
});

export default VisitTile;
