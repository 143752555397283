import { Box, Card, CardContent, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import RequestsCards from "./report-request-box/RequestsCards";
import FailedVisitsCollapsible from "./service-box/FailedVisitsCollapsible";
import OutstandingRequestsCollapsible from "./service-box/OutstandingRequestsCollapsible";
import VendorHeader from "./VendorHeader";
import ServiceBox from "./ServiceBox";
import { useTheme } from '@mui/material/styles';
import { useGetAllLocationRemediationsQuery } from "api/services";
import { getLocationId } from "ticket/selectors";
import { mapKeys, camelCase } from "lodash";

const LocationServicesVendorBox = ({
  vendor,
  services,
  calendar,
  initialOpenState,
}) => {
  const [openServiceMap, setOpenServiceMap] = useState({});

  const theme = useTheme();

  useEffect(() => {
    const newOpenServiceMap = {};
    services?.forEach((service) => {
      if (
        service?.status === "terminated" ||
        service?.status === "account_closed"
      ) {
        newOpenServiceMap[service.id] = false;
      } else {
        newOpenServiceMap[service.id] = initialOpenState;
      }
    });
    setOpenServiceMap(newOpenServiceMap);
  }, [services, initialOpenState]);

  const handleOpenServiceChange = (serviceId, isOpen) => {
    setOpenServiceMap((prevState) => ({
      ...prevState,
      [serviceId]: isOpen,
    }));
  };

  const locationId = getLocationId();
  const remediationsData = useGetAllLocationRemediationsQuery(locationId, {skip: !locationId}).data?.remediations || [];
  const remediations = remediationsData.map(remediation => mapKeys(remediation, (_, key) => camelCase(key)));

  return (
      <Box sx={{background: 'white', borderRadius: '4px' }}>

        <VendorHeader vendor={vendor} calendar={calendar} />

        {services?.map((service, key) => (
          <Box key={key}>
            <ServiceBox
              key={service.id}
              service={service}
              openService={openServiceMap[service.id]}
              setOpenServiceMap={(isOpen) =>
                handleOpenServiceChange(service.id, isOpen)
              }
            />

            <Box sx={{
              background: '#23272D',
              color: "#fff",
              borderRadius: '0 0 4px 4px',
              border: '1px solid #656D78',
              borderTop: 'none',
            }}>
              {openServiceMap[service.id] && (
                <Box sx={{
                    // borderBottom: `1px dashed #656D78`,
                  }}
                >
                  <FailedVisitsCollapsible service={service} />
                </Box>
              )}

              <RequestsCards requests={service?.requests} remediations={remediations} />

              {openServiceMap[service.id] && (
                <Box sx={{ px: '10px', mt: 0 }}>
                  <OutstandingRequestsCollapsible service={service} />
                </Box>
              )}
            </Box>
          </Box>
        ))}
      </Box>
  );
};



export default LocationServicesVendorBox;
