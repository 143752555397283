import { Box, Divider, Typography, Chip, Stack, Grid } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import { TextSmallMuted, BoxVendorServiceArea } from 'sharedComponents';
import { Link } from "react-router-dom";
import { startCase } from "lodash";
import { useContext } from "react";
import { formatDate } from "sharedUtils";
import AppContext from "ticket/AppContext";
import { STATUS_COLORS } from "./forms/common/VisitBoxButtons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";


import { useBinFetch } from "sharedUtils";



const VendorHeader = ({ vendor, calendar }) => {

  const { nextVisit, lastVisit } = calendar;
  const { onSelectDate } = useContext(AppContext);
  const theme = useTheme();

  const getStatusColors = (visit) => {
    return theme['palette']['visits'][visit?.status] || {
      main: theme.palette.grey[400],
      contrastText: theme.palette.white,
    };
  };

  const lastVisitColors = getStatusColors(lastVisit);
  const nextVisitColors = getStatusColors(nextVisit);

  return (

    <>
      <BoxVendorServiceArea sx={{ pt: '6px' }}>
        <Stack direction="row" spacing={2}>
          <Box sx={{ flex: 1, textAlign: 'left' }}>
            <strong>{vendor.name}</strong>
            <a href={`/admin/vendors/${vendor?.id}`}>
              <small>
                <FontAwesomeIcon icon={faEye} />
              </small>
            </a>
            <TextSmallMuted sx={{ pl: 2 }}> {vendor?.telephone} </TextSmallMuted>
          </Box>

          <Box sx={{ flex: 1, textAlign: 'right' }}>
            <Box
              component="img"
              src={vendor.logo.header.url}
              alt={vendor.name}
              onClick={() => window.open(`/admin/vendors/${vendor?.id}`, "_blank")}
              sx={{
                cursor: "pointer",
                height: '20px',
                objectFit: "contain",
              }}
            />
          </Box>
        </Stack>
      </BoxVendorServiceArea>


      <BoxVendorServiceArea sx={{ pt: '6px', pb: '6px'}}>

       {/* <Stack direction="row" spacing={2}>
          <Box sx={{ flex: 1, textAlign: 'center' }}>
*/}


              <TextSmallMuted sx={{mr: 1}}>Next Visit</TextSmallMuted>

              <Box component="span" sx={{ borderRadius: '16px', overflow: 'hidden' }}>
                <Chip
                  label={nextVisit?.date && formatDate(nextVisit?.date)}
                  onClick={() => onSelectDate(nextVisit?.date, "visitDetails")}
                  size="small"
                  sx={{
                    cursor: "pointer",
                    fontWeight: 'bold',
                    color: theme.palette.common.white,
                    backgroundColor: "#7BB841",
                    borderRadius: '16px 0 0 16px',
                    '&:hover': { backgroundColor: nextVisitColors['main'] },
                  }}
                />

                <Chip
                  label={startCase(nextVisit?.status)}
                  size="small"
                  sx={{
                    color: nextVisitColors['contrastText'],
                    backgroundColor: nextVisitColors['main'],
                    borderRadius: '0 16px 16px 0',

                  }}
                />
              </Box>


 {/*         </Box>
          <Box sx={{ flex: 1, textAlign: 'center' }}>
*/}


              <TextSmallMuted sx={{ml: 2, mr: 1}}>Last</TextSmallMuted>
              <Box component="span" sx={{ borderRadius: '16px', overflow: 'hidden' }}>
                <Chip
                    label={lastVisit?.date && formatDate(lastVisit?.date)}
                    onClick={() => onSelectDate(lastVisit?.date, "visitDetails")}
                    size="small"
                    sx={{
                      cursor: "pointer",
                      fontWeight: 'bold',
                      color: theme.palette.common.white,
                      backgroundColor: "#7BB841",
                      borderRadius: '16px 0 0 16px',
                      '&:hover': { backgroundColor: lastVisitColors['main'] },
                    }}
                  />

                <Chip
                  label={startCase(lastVisit?.status)}
                  size="small"
                  sx={{
                    color: lastVisitColors['contrastText'],
                    backgroundColor: lastVisitColors['main'],
                    borderRadius: '0 16px 16px 0',

                  }}
                />
              </Box>

{/*
          </Box>
        </Stack>*/}

      </BoxVendorServiceArea>

    </>
  );

};

export default VendorHeader;
