import React from "react";
import { Typography } from "@mui/material";
import { ServiceSuspensionRequest } from "service/ticket/types";
import { formatDate } from "sharedUtils";

interface ServiceSuspensionRequestCardDetailsProps {
  request: ServiceSuspensionRequest;
}

const ServiceSuspensionRequestCardDetails: React.FC<
  ServiceSuspensionRequestCardDetailsProps
> = ({ request }) => {
  const immediateEffectText = request.withImmediateEffect
    ? "with immediate effect"
    : "";
  const noVisitsExpectedAfterText = request.noVisitsExpectedAfter
    ? `Effective From: ${formatDate(request.noVisitsExpectedAfter)}`
    : "";

  return (
    <>
      <Typography>
        Vendor has been requested to suspend service {immediateEffectText}
      </Typography>
      <Typography>Reason: {request?.reasonName}</Typography>

      {request.noVisitsExpectedAfter && <Typography>{noVisitsExpectedAfterText}</Typography>}
    </>
  );
};

export default ServiceSuspensionRequestCardDetails;
