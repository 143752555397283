import { Box, Chip, Link, Typography } from "@mui/material";
import { formatDate } from "sharedUtils";
import PdfModal from "service/forms/common/PdfModal";
import { useState } from "react";
import ReportLogLayout from "./ReportLogLayout";
import { mapKeys, camelCase } from "lodash";
import { useGetLocationRemediationQuery } from "api/services";
import { useTicket } from "ticket/selectors";

const statusMessages = {
  RemediationSent: { text: "sent", color: "#FFA600" },
  RemediationCancelled: { text: "Cancelled", color: "red" },
  RemediationReplyLogged: { text: "response received", color: "#19E53B" },
  RemediationResponseAccepted: {
    text: "The remediation response is logged as a Valid PDF",
    color: "#19E53B",
  },
  RemediationResponseRejected: {
    text: "Remediation review required for Adhoc Visit by Kim (contract manager)",
    color: "#FFA600",
  },
};

const RemediationLog = ({ request }) => {
  const [openModal, setOpenModal] = useState(false);
  const statusMessage = statusMessages[request.name];
  const { location } = useTicket();
  const remediationData = useGetLocationRemediationQuery(location?.id, {
    skip: !location?.id,
  }).data?.remediation;
  const remediation = remediationData
    ? mapKeys(remediationData, (_, k) => camelCase(k))
    : null;

  const logHeader = (
    <>
      Remediation
      <Typography
        component="span"
        sx={{
          fontSize: "13px",
          fontWeight: "700",
          color: statusMessage.color,
        }}
      >
        {` ${statusMessage.text}`}
      </Typography>
      <span style={{ color: "#828B98" }}> for{" "}</span>
      <strong>{request.data.serviceActionReason}</strong>
    </>
  );

  const extraLinks = remediation?.status !== 'cancelled' && (
    <Link
      onClick={() => setOpenModal(true)}
      underline="none"
      sx={{ fontSize: "11px" }}
    >
      View
    </Link>
  );

  const logContent = (
    <>
      <Box sx={{ mb: "7px" }}>
        <strong>Contract Failure &nbsp;</strong>
        <Chip
          size="xsmall"
          color="error"
          label={formatDate(remediation?.dateOfContractFailure)}
        />
      </Box>

      {request.name == "RemediationSent"
        ? request.data.notice.message
        : undefined}
    </>
  );

  const pdfUrl = request.name == "RemediationSent" ? request.data.notice?.noticePdfUrl : request.data.reply?.attachmentUrl;

  return (
    <>
      <ReportLogLayout
        logHeader={logHeader}
        logContent={logContent}
        extraLinks={extraLinks}
        object={request}
      />
      <PdfModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        pdfUrl={pdfUrl}
      />
    </>
  );
};

export default RemediationLog;
