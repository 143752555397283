import { useState } from "react";
import { Channel } from "service/ticket/types";
import {
  getDefaultContactId,
  getDefaultResponseExpected,
  getTicketId,
} from "ticket/selectors";

export const useResponse = (channel: Channel) => {
  const ticketId = getTicketId();

  const [responseExpected, setResponseExpected] = useState(
    getDefaultResponseExpected(channel)
  );
  const [message, setMessage] = useState();
  const [messageOverridden, setMessageOverridden] = useState(false);
  const [toContactId, setToContactId] = useState(getDefaultContactId(channel));

  return {
    channel,
    ticketId,
    toContactId,
    responseExpected,
    message,
    messageOverridden,
    setResponseExpected,
    setToContactId,
    setMessage,
    setMessageOverridden,
  };
};
