import { DebugButton } from "service/GlobalDebugger";
import {
  Box,
  Card,
  CardContent,
  Collapse,
  List,
  Stack,
  Typography,
} from "@mui/material";
import { formatDate } from "sharedUtils";
import ShowMoreDivider from "service/forms/common/ShowMoreDivider";
import React, { useContext } from "react";
import { ServiceResumptionRequest } from "service/ticket/types";
import AppContext from "ticket/AppContext";
import { ActionButtons } from "./AdhocVisitRequestCard";
import Debugger from "service/forms/Debugger";
import CustomTimelineNotes from "service/forms/common/CustomTimeLineNotes";

interface ServiceResumptionRequestCardProps {
  request: ServiceResumptionRequest;
  isOpen: boolean;
  toggleOpen: () => void;
}

const ServiceResumptionRequestCard: React.FC<
  ServiceResumptionRequestCardProps
> = ({ request, isOpen, toggleOpen }) => {
  const { notes } = request;
  const { selectedContainer } = useContext(AppContext);

  return (
    <Box
      sx={{
        p: 1,
        pt: 0,
        position: "relative",
        ...(selectedContainer === request.id && {
          "& .MuiPaper-root": {
            border: "3px solid #6c8c89",
          },
        }),
      }}
      data-link-card={request.id}
    >
      <Card
        variant="outlined"
        sx={{
          borderRadius: 2,
          background: "#262B30",
          "& .MuiCardContent-root:last-child": { pb: 1 },
          "& *": { fontSize: "12px", color: "#fff" },
        }}
      >
        <CardContent>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={1}
          >
            <Box display={"flex"} alignItems={"center"}>
              <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                Service Resumption Request
              </Typography>
              <Typography color="grey" sx={{ ml: "4px", fontSize: "10px" }}>
                on {formatDate(request?.requestedAt)}
              </Typography>
            </Box>
            <ActionButtons
              actions={request?.actions}
              isActive={request?.isActive}
              request={request}
              linkSelected={selectedContainer === request.id}
            />
          </Stack>

          <Typography>
            Vendor has confirmed resumption of service from{" "}
            {formatDate(request?.noVisitsExpectedAfter)}
          </Typography>

          {notes && notes.length > 0 && (
            <List sx={{ width: "100%" }} component="nav">
              <ShowMoreDivider toggleOpen={toggleOpen} isOpen={isOpen} />
              <Collapse in={isOpen} timeout="auto" unmountOnExit>
                <CustomTimelineNotes notes={notes} />
              </Collapse>
            </List>
          )}
        </CardContent>
        <DebugButton {...request} />
      </Card>
    </Box>
  );
};

export default ServiceResumptionRequestCard;
