import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Alert, Box, Collapse, ListItemButton } from "@mui/material";
import { groupBy, map, orderBy } from "lodash";
import { useState } from "react";
import {
  ServiceResumptionRequest,
  ServiceResumptionRequests,
} from "service/ticket/types";
import { getTicketId } from "ticket/selectors";
import ServiceResumptionRequestCard from "./ServiceResumptionRequestCard";
import { useTypedSelector } from "ticket/app/store";

interface ServiceResumptionRequestTabProps {
  serviceResumptionRequests: ServiceResumptionRequests;
}

const ServiceResumptionRequestTab: React.FC<
  ServiceResumptionRequestTabProps
> = ({ serviceResumptionRequests }) => {
  const ticketId = useTypedSelector(getTicketId);
  const [openStates, setOpenStates] = useState(() => {
    let initialStates = {};
    if (serviceResumptionRequests) {
      initialStates = serviceResumptionRequests.reduce((acc, request) => {
        const isTicketId = request.linkedTicketIds[0] === Number(ticketId);

        acc[request.id] = false;
        acc[`group-${request.linkedTicketIds[0]}`] = isTicketId;
        return acc;
      }, {});
    }
    return initialStates;
  });

  const handleClick = (id: string) => {
    setOpenStates((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  const groupedRequests = groupBy(
    orderBy(serviceResumptionRequests, ["requestedAt"], ["desc"]),
    (request) => request.linkedTicketIds[0].toString()
  );

  const renderGroup = (
    requests: ServiceResumptionRequest[],
    currentTicketId
  ) => (
    <Box key={currentTicketId}>
      <ListItemButton
        onClick={() => handleClick(`group-${currentTicketId}`)}
        sx={{ fontSize: "12px", fontWeight: "bold", pb: 1, color: "#fff" }}
      >
        {currentTicketId === ticketId
          ? "Requests on this Ticket"
          : `Ticket ID: ${currentTicketId}`}
        {openStates[`group-${currentTicketId}`] ? (
          <ExpandLess />
        ) : (
          <ExpandMore />
        )}
      </ListItemButton>
      <Collapse
        in={openStates[`group-${currentTicketId}`]}
        timeout="auto"
        unmountOnExit
      >
        {requests.map((request) => (
          <ServiceResumptionRequestCard
            key={request.id}
            request={request}
            isOpen={openStates[request.id]}
            toggleOpen={() => handleClick(request.id)}
          />
        ))}
      </Collapse>
    </Box>
  );

  return (
    <>
      {Object.values(serviceResumptionRequests)?.length === 0 && (
        <Alert severity="warning" sx={{ p: 2, fontSize: "14px" }}>
          No requests to show
        </Alert>
      )}

      {/* Render the group with the specific ticketId first if it exists */}
      {groupedRequests[ticketId] &&
        renderGroup(groupedRequests[ticketId], ticketId)}

      {/* Render other groups */}
      {map(groupedRequests, (requests, currentTicketId) => {
        if (currentTicketId !== ticketId.toString()) {
          return renderGroup(requests, currentTicketId);
        }
      })}
    </>
  );
};
export default ServiceResumptionRequestTab;
