export const scrollToBottom = () => {
  const footer = document.querySelector(".footer");
  if (footer) {
    footer.scrollIntoView({ behavior: "smooth", block: "end" });
  }
};

export const extractIdFromUrl = (url) => {
  const regex =
    /\/(service_actions|stock_delivery_requests|adhoc_visit_requests|failed_visit_reports)\/([0-9a-fA-F-]+)/;
  const match = url?.match(regex);
  return match ? match[2] : "";
};
