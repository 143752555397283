import "./styles.scss";
import Debugger from "./Debugger";
import { ModelErrors } from "sharedComponents";
import { Stack, Typography } from "@mui/material";
import NotesSection from "./common/NotesSection";
import { DebugButton } from "service/GlobalDebugger";

interface FormProps {
  notes: string;
  sourceContactId: number;
}

interface ContextProps {
  serviceName: string;
  visitPlanName: string;
}

interface AcceptVisitFrequencyChangeFormProps {
  form: FormProps;
  context: ContextProps;
  errors: any;
  onSetForm: (form: any) => void;
}

const AcceptVisitFrequencyChangeForm: React.FC<
  AcceptVisitFrequencyChangeFormProps
> = ({ form, context, errors, onSetForm }) => {
  const { visitPlanName, serviceName } = context;

  return (
    <div>
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
      >
        <Typography variant="h4" sx={{ fontWeight: "bold" }}>
          Accept Visit Frequency of {visitPlanName}
        </Typography>
        <Typography color="grey" variant="h5" sx={{ fontWeight: "bold" }}>
          / {serviceName}
        </Typography>
      </Stack>

      <p className="mt-2">
        <strong>
          The current visit frequency of {visitPlanName} will be accepted
        </strong>
      </p>

      <NotesSection
        form={form}
        onSetForm={onSetForm}
        context={context}
        contactPlaceholder="Who accepted the change?"
        contactLabel="Accepted By"
        dateLabel="Accepted at"
        dateValue={form?.acceptedAt}
          onChange={(newValue) =>
            onSetForm({
              acceptedAt: newValue.format("DD/MM/YYYY"),
            })
          }
      />
      <DebugButton {...form} />
      <ModelErrors errors={errors} />
    </div>
  );
};

export default AcceptVisitFrequencyChangeForm;
