import LinkIcon from "@mui/icons-material/Link";
import {
  Box, Card, CardContent, Chip, Collapse, IconButton, Link, Stack, TableCell, TableRow, Typography,
} from "@mui/material";
import { Contact } from "api/tickets";
import { flatMap, map, startCase, mapKeys, camelCase } from "lodash";
import { useContext, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import BinDot from "service/BinDot";
import { DebugButton } from "service/GlobalDebugger";
import { formatDate, useBinFetch } from "sharedUtils";
import AppContext from "ticket/AppContext";
import { getLocationId, useTicket } from "ticket/selectors";
import { AdhocCardTable } from "./AdhocVisitRequestCard";
import ReportRequestCardLayout from "./ReportRequestCardLayout"
import { TypoHaveInfo, TypoNeedInfo } from 'sharedComponents';

const StockDeliveryRequestCard = ({ request }) => {
  const { stakeholderContacts } = useTicket() || {};
  const { deliveryUnits, requestDeliveryCodes } = request;

  const locationId = getLocationId();

  const {
    currentTicketId,
    highlightedIds,
    setHighlightedIds,
    onOpenRemediation,
    setRequestedAtDate,
    setEventId,
    setRequestType
  } = useContext(AppContext);

  const { binTypes, binGroups } = useBinFetch(requestDeliveryCodes);

  const [open, setOpen] = useState(true);

  const isInCurrentPath =
    currentTicketId === String(request?.linkedTicketIds[0]);

  const isSelected = useMemo(() => {
    return (
      request?.replyIds.length > 0 &&
      request?.replyIds?.every((id) => highlightedIds?.includes(String(id)))
    );
  }, [highlightedIds]);

  const allStakeholders: Contact[] = flatMap(
    stakeholderContacts || [],
    (x) => x,
  );
  const inputtedBy = allStakeholders?.find(
    (x) => x.id === request?.inputtedById,
  );

  const deliveryRows = map(
    deliveryUnits,
    ({ serviceCode, quantity: deliveryQuantity, visitActionId }) => {
      const binType = binTypes[serviceCode];

      const binGroup = binGroups[binType?.binGroupId];

      return (
        <TableRow key={serviceCode}>
          <TableCell
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            <BinDot
              binGroup={binGroup}
              tooltipName={true}
              tooltipPlace="top"
              {...binType}
            />{" "}
            <Typography>{binType?.name}</Typography>
          </TableCell>
          <TableCell>{deliveryQuantity || 0}</TableCell>
          <TableCell
            sx={{
              color: "#5F6369 !important",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {visitActionId ? <span>{startCase(visitActionId)}</span> : null}

            <span>{serviceCode}</span>
          </TableCell>
        </TableRow>
      );
    },
  );

  const title = (
    <span>
      Delivery of Stock Request
      {!request.date && !request.cancelledAt && (
        <TypoNeedInfo><strong> Awaiting Date </strong></TypoNeedInfo>
      )}
    </span>
  )

  const label = "Deliver as specified"

  const body = (
    <>


            {deliveryRows.length > 0 && (
              <AdhocCardTable
                rows={deliveryRows}
                headers={["", "Deliver Additional", "Code"]}
              />
            )}

            {request?.date ? (
              <Typography sx={{ fontSize: "13px", color: "#828B98" }}>
                Visit confirmed for{" "}
                <span style={{ color: "#19E53B" }}>
                  {formatDate(request?.date)}
                </span>
                <Typography
                  component="span"
                  sx={{ fontSize: "11px", color: "#828B98" }}
                >
                  {" "}
                  on{" "}
                  <span style={{ color: "#BFBFBF" }}>
                    {formatDate(request?.confirmedAt)}
                  </span>{" "}
                  by{" "}
                  <span style={{ color: "#BFBFBF" }}>
                    {inputtedBy?.nameWithoutEmail || "No Agent"}
                  </span>
                </Typography>
              </Typography>
            ) : request?.cancelledAt ? (
              <Typography sx={{ fontSize: "13px", color: "#828B98" }}>
                Request was cancelled on{" "}
                <span style={{ color: "red" }}>
                  {formatDate(request?.cancelledAt)}
                </span>{" "}
                <Typography component="span" sx={{ fontSize: "11px" }}>
                  by{" "}
                  <span style={{ color: "#BFBFBF" }}>
                    {inputtedBy?.nameWithoutEmail || "No Agent"}
                  </span>
                </Typography>
              </Typography>
            ) : (
              <Typography sx={{ fontSize: "13px", color: "#828B98" }}>
                Visit confirmed for{" "}
                <Chip
                  label="Awaiting Date"
                  size="small"
                  sx={{
                    background: "#5F6369",
                    color: "#BFBFBF",
                    fontSize: "11px",
                    fontWeight: "bold",
                  }}
                />
              </Typography>
            )
          }

    </>

  )

  return (
    <ReportRequestCardLayout title={title} links='' label={label} body={body} request={request} />
  );
};

export default StockDeliveryRequestCard;
