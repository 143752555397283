import _ from 'lodashExtended'

const getAdhocCollectionBooking = (id, state) => {
  return state.issues.adhocCollectionBookings[id]
}

const getAdhocCollectionBookingsForServiceId = (serviceId, state) => {
  return _(state.issues.adhocCollectionBookings).filter({ serviceId }).reject({_destroy: '1'}).value()
}

export {
  getAdhocCollectionBooking,
  getAdhocCollectionBookingsForServiceId
}
