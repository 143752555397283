import { FC } from "react";
import "./styles.scss";
import Select from "react-select";
import { ModelErrors } from "sharedComponents";
import { Stack, Typography } from "@mui/material";
import _, { find } from "lodash";
import NotesSection from "./common/NotesSection";

interface VisitPlan {
  name: string;
  slug: string;
}

interface FormProps {
  visitPlanSlug: string;
  notes: string;
  sourceContactId: number;
  requestedAt: string;
}

interface ContextProps {
  serviceName: string;
  vendorLocationCode: string;
  visitPlanName: string;
  visitPlans: VisitPlan[];
}

interface RequestVisitFrequencyChangeFormProps {
  form: FormProps;
  context: ContextProps;
  errors: any;
  onSetForm: (newForm: any) => void;
}

const RequestVisitFrequencyChangeForm: FC<
  RequestVisitFrequencyChangeFormProps
> = ({ form, context, errors, onSetForm }) => {
  const { visitPlanSlug } = form;

  const { visitPlanName, serviceName, visitPlans, requestedVisitPlanName } = context;

  const visitPlanOptions = _.map(visitPlans, ({ name, slug }) => {
    return { label: name, value: slug };
  });

  const currentVisitPlanSlug = find(visitPlanOptions, { label: requestedVisitPlanName || visitPlanName });

  return (
    <div>
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
      >
        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
          Request Visit Frequency Change
        </Typography>
        <Typography color="grey" variant="h6" sx={{ fontWeight: "bold" }}>
          / {serviceName}
        </Typography>
      </Stack>

      <div className="mt-1">
        <div className="form-group row">
          <div className="input-wrapper col-md-12">
            <label>New Frequency</label>
            <Select
              styles={{
                // Fixes the overlapping problem of the component
                menu: (provided) => ({ ...provided, zIndex: 9999 }),
              }}
              options={visitPlanOptions}
              value={
                _.find(visitPlanOptions, {
                  value: visitPlanSlug,
                }) || currentVisitPlanSlug
              }
              onChange={({ value }) => onSetForm({ visitPlanSlug: value })}
            />
          </div>
        </div>
      </div>

      <NotesSection
        form={form}
        onSetForm={onSetForm}
        context={context}
        contactPlaceholder="Who requested the change?"
        contactLabel="Requested by"
        dateLabel="Requested on"
        dateValue={form?.requestedAt}
        onChange={(newValue) =>
          onSetForm({
            requestedAt: newValue.format("DD/MM/YYYY"),
          })
        }
      />

      <ModelErrors errors={errors} />
    </div>
  );
};

export default RequestVisitFrequencyChangeForm;
